import React from 'react';

export default function Flag({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 1280 800">
      <rect width="1280" height="400" fill="#e9e8e7" id="rect2209" />
      <rect width="1280" height="400" fill="#d4213d" y="400" id="rect2211" />
      {/* <rect width="1280" height="800" fill="none" stroke-width="17" stroke="black" id="rect2213" /> */}
    </svg>
  );
}
