import _ from 'lodash';
import { extractMapPoints, extractRkmKm, extractRiverGuide } from './extractors';
import { distance, distanceGps } from '../utility/mathutil';

function interpolateGuide(guide) {
  let lastGpsIndex = -1;
  let toInterpolateIndexes = [];
  for (let index = 0; index < guide.length; index += 1) {
    if (guide[index].gps) {
      if (lastGpsIndex >= 0) {
        const lastGps = guide[lastGpsIndex].gps;
        const lastRkm = extractRkmKm(guide[lastGpsIndex]);
        const nextGps = guide[index].gps;
        const nextRkm = extractRkmKm(guide[index]);
        for (const ii of toInterpolateIndexes) {
          const t = (extractRkmKm(guide[ii]) - lastRkm) / (nextRkm - lastRkm);
          const latitude = lastGps.latitude + (nextGps.latitude - lastGps.latitude) * t;
          const longitude = lastGps.longitude + (nextGps.longitude - lastGps.longitude) * t;
          guide[ii].gps = { latitude, longitude };
        }
      }

      lastGpsIndex = index;
      toInterpolateIndexes = [];
    } else {
      toInterpolateIndexes.push(index);
    }
  }
}

function extrapolatePoint(guide, known1, known2, compute) {
  const gps1 = guide[known1].gps;
  const gps2 = guide[known2].gps;
  const rkm1 = extractRkmKm(guide[known1]);
  const rkm2 = extractRkmKm(guide[known2]);
  const rkm = extractRkmKm(guide[compute]);
  const t = (rkm - rkm1) / (rkm2 - rkm1);
  const latitude = gps1.latitude + (gps2.latitude - gps1.latitude) * t;
  const longitude = gps1.longitude + (gps2.longitude - gps1.longitude) * t;
  guide[compute].gps = { latitude, longitude };
}

function extrapolateGuide(guide) {
  const firstGps = _.findIndex(guide, (x) => x['gps']);
  if (guide[firstGps + 1] && guide[firstGps + 1].gps) {
    for (let ii = 0; ii < firstGps; ii += 1) {
      extrapolatePoint(guide, firstGps, firstGps + 1, ii);
    }
  }

  const lastGps = _.findLastIndex(guide, (x) => x['gps']);
  if (guide[lastGps - 1] && guide[lastGps - 1].gps) {
    for (let ii = guide.length - 1; ii > lastGps; ii -= 1) {
      extrapolatePoint(guide, lastGps, lastGps - 1, ii);
    }
  }
}

export function guideHasGps(guide) {
  return guide.filter((x) => x.gps).length >= 2;
}

export function addGpsToGuide(guide) {
  for (const rkmDetail of guide) {
    const points = extractMapPoints(rkmDetail);
    if (points.length > 0) {
      rkmDetail.gps = points[0].gps;
    }
  }

  if (!guideHasGps(guide)) return;

  interpolateGuide(guide);
  extrapolateGuide(guide);
}

function indexOfNearest(guide) {
  var nearest = 0;
  for (var i = 1; i < guide.length; i++) {
    if (guide[i].distance < guide[nearest].distance) nearest = i;
  }
  return nearest;
}

function interpolateRkm(rkm1, rkm2, distComputed, distTotal) {
  // console.log('interpolateRkm', rkm1, rkm2, distComputed, distTotal);
  const t = distComputed / distTotal;
  return rkm1 + (rkm2 - rkm1) * t;
}

function addCurrentLocationEdges(guide, i0, i1) {
  const x = distanceGps(guide[i0].gps, guide[i1].gps);
  const y = guide[i0].distance;
  const z = guide[i1].distance;
  if (x * x + y * y > z * z) {
    return false;
  } else {
    // is outside
    const location = {
      currentLocation: true,
      rkm: i0 == 0 ? extractRkmKm(guide[i0]) - y : extractRkmKm(guide[i0]) + y,
    };

    if (i0 == 0) {
      guide.splice(0, 0, location);
    }
    if (i0 == guide.length - 1) {
      guide.push(location);
    }
    return true;
  }
}

function getNearest2Index(guide, nearestIndex) {
  if (nearestIndex == 0) return 1;
  if (nearestIndex == guide.length - 1) return guide.length - 2;
  return guide[nearestIndex - 1].distance < guide[nearestIndex + 1].distance ? nearestIndex - 1 : nearestIndex + 1;
}

export function addCurrentLocationToGuide(guide, location) {
  if (guide.find((x) => !x.gps)) throw new Error('All items must have GPS');
  if (!location) throw new Error('Invalid location');

  guide = _.cloneDeep(guide);

  for (const rkmDetail of guide) {
    rkmDetail.distance = distanceGps(rkmDetail.gps, location);
  }

  const nearestIndex = indexOfNearest(guide);
  if (nearestIndex == 0) {
    if (addCurrentLocationEdges(guide, 0, 1)) return guide;
  }
  if (nearestIndex == guide.length - 1) {
    if (addCurrentLocationEdges(guide, guide.length - 1, guide.length - 2)) return guide;
  }
  const nearest2Index = getNearest2Index(guide, nearestIndex);
  const index1 = Math.min(nearestIndex, nearest2Index);
  const index2 = Math.max(nearestIndex, nearest2Index);
  guide.splice(index2, 0, {
    currentLocation: true,
    rkm: interpolateRkm(
      extractRkmKm(guide[index1]),
      extractRkmKm(guide[index2]),
      distanceGps(guide[index1].gps, location),
      distanceGps(guide[index1].gps, location) + distanceGps(guide[index2].gps, location)
    ),
  });

  return guide;
}

export function findNearestCoordinates(riverDetail, rkm, preferIcon = null) {
  const guide = extractRiverGuide(riverDetail);
  let nearest = null;
  for (const rkmDetail of guide) {
    if (Math.abs(extractRkmKm(rkmDetail) - rkm) > 1) continue;
    const positions = extractMapPoints(rkmDetail);
    if (positions.length == 0) continue;
    if (nearest == null || Math.abs(extractRkmKm(rkmDetail) - rkm) < Math.abs(extractRkmKm(nearest) - rkm)) {
      nearest = rkmDetail;
    }
  }
  if (nearest == null) return null;
  const positions = extractMapPoints(nearest);
  const prefer = positions.find((x) => x.icon == preferIcon);
  if (prefer) return prefer.gps;
  return positions[0].gps;
}
