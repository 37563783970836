import React from 'react';

export default function Flag({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 6 3">
      <rect fill="#436F4D" width="6" height="3" />
      <rect fill="#FFF" width="6" height="2" />
      <rect fill="#CD2A3E" width="6" height="1" />
    </svg>
  );
}
