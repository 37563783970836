import React from 'react';

export default function Flag({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 900 450">
      <title>Flag of Belarus</title>
      <rect fill="#ce1720" width="100%" height="100%" />

      <path d="m0 300h900v150h-900z" fill="#007c30" />
    </svg>
  );
}
