import _ from 'lodash';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import StreetviewIcon from '@material-ui/icons/Streetview';
import YoutubeIcon from '@material-ui/icons/YouTube';
import EditIcon from '@material-ui/icons/Edit';
import useReactRouter from 'use-react-router';
import CloseIcon from '@material-ui/icons/Close';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NameValueView from '../ui-elements/NameValueView';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { formatDistance } from '../utility/mathutil';
import CampIcon from '../icons/Camp';
import LayersIcon from '@material-ui/icons/Layers';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseButtonDialogTitle from '../ui-elements/CloseButtonDialogTitle';
import useT from '../utility/useT';
import {
  extractRkmDescription,
  extractPhotos,
  extractRkmKm,
  extractMapPoints,
  extractRiverName,
  extractRkmTitle,
  extractRiverGuide,
  extractNextRkmDetail,
  extractBeforeRkmDetail,
  extractRkmLinks,
} from '../datalayer/extractors';
// import CampInfoView from './CampInfoView';
import GpsView from './GpsView';
import useRouterDialog from '../utility/useRouterDialog';
import FeedbackDialog from '../feedback/FeedbackDialog';
import { Button } from '@material-ui/core';
import { Trans } from 'react-i18next';
import AppLayout from '../layout/AppLayout';
import RkmDetailMap from './RkmDetailMap';
import RkmListItem from '../riverdetail/RkmListItem';
import { findByLabelText } from '@testing-library/react';
import AvatarBar from '../ui-elements/AvatarBar';
import AvatarButton from '../ui-elements/AvatarButton';
import Panel from '../ui-elements/Panel';
import useOnlineStatus from '../utility/useOnlineStatus';
import { useSetDetailMapType } from '../utility/globalState';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
    // backgroundColor: theme.palette.background.paper,
  },
  image: {
    maxWidth: '100%',
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  streetviewButton: {
    color: 'gold',
  },
  rkmSeparator: {
    color: 'gray',
    backgroundColor: '#EEE',
    marginTop: '20px',
  },
  campIconWrapper: {
    marginRight: '10px',
  },
}));

export function CampInfoView({
  nazev,
  adresa,
  web,
  telefon,
  provoz,
  dalsi_moznosti,
  prijezd,
  gps,
  aktivity,
  ikonky_tab,
}) {
  const classes = useStyles();
  const t = useT();

  if (!nazev) return null;

  return (
    <>
      <ListItem className={classes.rkmSeparator}>
        <span className={classes.campIconWrapper}>
          <CampIcon size={25} color="blue" />
        </span>{' '}
        {nazev}
      </ListItem>
      <ListItem>
        <div>
          <NameValueView name={t('CampBackground')} value={aktivity} />
          <NameValueView name={t('OperatingHours')} value={provoz} />
          <NameValueView name={t('NextPossibilities')} value={dalsi_moznosti} />
          <NameValueView name={t('Arrival')} value={prijezd} />
          <NameValueView name={t('AddresOrDescription')} value={adresa} />
          <NameValueView name={t('Web')} value={web} link={web} />
          <NameValueView name={t('Phone')} value={telefon} link={`tel:${telefon}`} />
        </div>
      </ListItem>
    </>
  );
}

function RkmDetailPage({ riverDetail, rkmDetail: rkmDetailInit = undefined }) {
  const theme = useTheme();
  const { history } = useReactRouter();
  const classes = useStyles();
  const t = useT();
  const [rkmDetail, setRkmDetail] = React.useState(rkmDetailInit);
  // const description = extractRkmDescription(rkmDetail);
  const title = React.useMemo(() => extractRkmTitle(rkmDetail), [rkmDetail]);
  const photosAll = extractPhotos(rkmDetail);
  const photos = photosAll.filter((x) => x.photo_file);
  // const km = extractRkmKm(rkmDetail);
  // const pointsNoCamps = extractMapPoints(rkmDetail, false);
  const pointsAll = extractMapPoints(rkmDetail);
  const camps = extractMapPoints(rkmDetail, true);
  // const riverName = extractRiverName(riverDetail);
  const links = extractRkmLinks(rkmDetail);
  const online = useOnlineStatus();
  const setDetailMapType = useSetDetailMapType();

  const [openFeedback, feedbackDialogProps] = useRouterDialog('feedbackDetail');

  const openLink = (link) =>
    history.push(history.location.pathname, {
      ...history.location.state,
      currentLinkUrl: link,
    });

  const currentLinkUrl = history.location.state && history.location.state.currentLinkUrl;

  //console.log()
  //theme.palette.primary.main

  // // const headerButtons = [];
  // const headerButtons = [
  //   // @ts-ignore
  //   <IconButton aria-label="Photo" onClick={openFeedback}>
  //     <EditIcon />
  //   </IconButton>,
  // ];

  // if (photo_sfer) {
  //   headerButtons.push(
  //     <IconButton aria-label="Photo" onClick={openStreetView} className={classes.streetviewButton}>
  //       <StreetviewIcon />
  //     </IconButton>
  //   );
  // }

  const rkmNextDetail = React.useMemo(() => extractNextRkmDetail(extractRiverGuide(riverDetail), rkmDetail), [
    riverDetail,
    rkmDetail,
  ]);
  const rkmBeforeDetail = React.useMemo(() => extractBeforeRkmDetail(extractRiverGuide(riverDetail), rkmDetail), [
    riverDetail,
    rkmDetail,
  ]);

  const handleGoNext = () => {
    setRkmDetail(rkmNextDetail);
  };

  return (
    <AppLayout title={title}>
      <RkmDetailMap rkmDetail={rkmDetail} key={extractRkmKm(rkmDetail)} />
      <AvatarBar usage="rkm">
        <AvatarButton
          Icon={NavigateBeforeIcon}
          onClick={rkmBeforeDetail ? () => setRkmDetail(rkmBeforeDetail) : null}
          color={rkmBeforeDetail ? theme.palette.primary.main : null}
        />
        <AvatarButton
          Icon={NavigateNextIcon}
          onClick={rkmNextDetail ? () => setRkmDetail(rkmNextDetail) : null}
          color={rkmNextDetail ? theme.palette.primary.main : null}
        />

        {online && <AvatarButton Icon={EditIcon} onClick={openFeedback} color={theme.palette.primary.main} />}
        {online &&
          links.map((link) => (
            <>
              {link.typ == 'youtube' && (
                <AvatarButton Icon={YoutubeIcon} onClick={() => openLink(link.text)} color="red" />
              )}
              {link.typ == 'streetview' && (
                <AvatarButton Icon={StreetviewIcon} onClick={() => openLink(link.text)} color="gold" />
              )}
            </>
          ))}
        {online && rkmDetail && rkmDetail.gps && (
          <AvatarButton
            Icon={LayersIcon}
            onClick={() => setDetailMapType((x) => (x == 'maps_google_photo' ? 'mapycz_turist' : 'maps_google_photo'))}
            color={theme.palette.primary.main}
          />
        )}
      </AvatarBar>

      {camps.map((camp) => (
        <CampInfoView {...camp.point} />
      ))}
      {/* <Paper>
        <div className={classes.wrapper}>
          <NameValueView name={t('RiverKm')} value={km} />
        </div>

        <div className={classes.wrapper} dangerouslySetInnerHTML={{ __html: description }} />
      </Paper> */}
      {/* <List> */}
      <ListItem className={classes.rkmSeparator}>{t('Description')}</ListItem>
      {rkmDetail && <RkmListItem rkmDetail={rkmDetail} />}

      {!online && pointsAll.length > 0 && (
        <>
          <ListItem className={classes.rkmSeparator}>{t('GPS')}</ListItem>
          <div className={classes.wrapper}>
            {pointsAll.map((point) => (
              <GpsView name={point.title} gps={point.gps} />
            ))}
          </div>
        </>
      )}

      {online &&
        (photos || []).map((photo) => (
          <>
            <ListItem className={classes.rkmSeparator}>{photo.photo_title}</ListItem>
            <img src={photo.photo_file} alt={photo.photo_title} className={classes.image} />
          </>
        ))}

      {rkmNextDetail && (
        <ListItem className={classes.rkmSeparator}>
          {t('FollowsAfter')} {formatDistance(extractRkmKm(rkmDetail), extractRkmKm(rkmNextDetail))}
        </ListItem>
      )}

      {rkmNextDetail && <RkmListItem rkmDetail={rkmNextDetail} onClick={handleGoNext} />}
      {/* </List> */}

      <FeedbackDialog rkmDetail={rkmDetail} riverDetail={riverDetail} {...feedbackDialogProps} />

      <Dialog onClose={() => history.goBack()} aria-labelledby="camera-dialog-title" open={!!currentLinkUrl} fullScreen>
        {!!currentLinkUrl && <iframe className={classes.iframe} src={currentLinkUrl} />}
        <Avatar onClick={() => history.goBack()} className={classes.closeButton}>
          <CloseIcon />
        </Avatar>
      </Dialog>
    </AppLayout>
  );
}

export default RkmDetailPage;
