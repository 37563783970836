interface NativeInterface {
  startWatchPosition();
  stopWatchPosition();
  openUrl(url: string);
  requestCamera();
  showUploadDialog();
}

export default function nativeInterface(): NativeInterface {
  return window['jsInterface'];
}
