import React from 'react';

export default function River({ size = 30 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 512.001 512.001" enable-background="new 0 0 512.001 512.001">
      <g>
        <g>
          <path d="M401.851,295.316c0,0-64.599-39.86-64.68-39.91c-4.649-2.869-10.91-1.356-13.77,3.259
			c-2.866,4.625-1.345,10.921,3.26,13.76c0,0,64.64,39.886,64.68,39.91c4.647,2.867,10.903,1.392,13.76-3.259
			C407.958,304.424,406.502,298.183,401.851,295.316z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M297.883,232.915c-6.387-0.001-11.203,6.079-9.743,12.295c0.581,2.476,2.283,4.725,4.525,6.145
			c1.539,0.975,3.333,1.56,5.196,1.56c3.499,0,6.689-1.769,8.519-4.748C310.278,241.821,305.378,232.916,297.883,232.915z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M194.333,258.148c-2.821-4.493-9.325-5.945-13.792-3.123c0,0-64.182,40.472-64.29,40.54
			c-4.625,2.916-6.036,9.164-3.123,13.785c1.751,2.779,5.13,4.408,8.475,4.502c1.882,0.053,3.753-0.38,5.318-1.367
			c0,0,64.245-40.521,64.29-40.55C195.835,269.019,197.239,262.776,194.333,258.148z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M219.691,232.145c-4.644,0-8.713,3.264-9.74,7.788c-1.399,6.163,3.48,12.212,9.76,12.212c4.635,0,8.727-3.263,9.75-7.791
			C230.862,238.154,226.028,232.145,219.691,232.145z"/>
        </g>
      </g>
      <g>
        <g>
          <path d="M509.548,455.464c-3.624-4.168-9.94-4.608-14.108-0.984c-10.663,9.271-20.531,18.883-34.522,22.75
			c-15.553,4.298-30.334,0.906-44.41-6.132l52.079-175.224c1.296-4.359-0.514-9.044-4.403-11.401l-48.183-29.195v-85.253
			c0-5.522-4.477-10-10-10h-20v-30c0-5.522-4.477-10-10-10h-31.5v-30c0-5.522-4.477-10-10-10h-48.5v-70c0-5.522-4.477-10-10-10h-40
			c-5.523,0-10,4.478-10,10v70h-48.5c-5.523,0-10,4.478-10,10v30h-31.5c-5.523,0-10,4.478-10,10v30h-20c-5.523,0-10,4.478-10,10
			v85.253l-48.182,29.194c-3.89,2.357-5.699,7.042-4.403,11.401c0,0,51.951,174.79,51.973,174.866
			c-0.887-2.984-16.889-4.949-19.718-5.315c-29.117-3.768-51.258,10.818-72.232,29.055c-4.168,3.624-4.608,9.939-0.985,14.107
			c3.459,3.978,9.896,4.646,14.107,0.985c21.608-18.784,45.969-33.103,74.559-18.806c25.864,12.931,52.636,18.476,79.575,4.3
			c21.722-11.434,42.684-21.307,66.93-9.763l24.927,11.87c21.326,10.156,45.674,9.922,66.799-0.642
			c13.018-6.509,26.249-15.171,41.119-16.193c14.576-1.001,28.66,4.426,41.467,10.835c16.919,8.46,36.726,10.15,54.894,4.971
			c16.735-4.771,28.914-15.429,41.731-26.573C512.731,465.947,513.171,459.632,509.548,455.464z M396.001,180.025v63.135
			l-104.198-63.135H396.001z M246.001,20.025h20v60h-20V20.025z M187.501,100.025h137v20h-137V100.025z M116.001,180.025h104.198
			L116.001,243.16V180.025z M246.001,467.14c0.002-1.777-14.255-5.055-16.12-5.459c-17.404-3.767-36.088-1.375-51.866,6.93
			c-11.448,6.025-22.493,13.054-35.586,14.74c-0.024,0.003-0.048,0.006-0.072,0.009c-7.65,0.93-15.48,0.367-22.884-1.792
			L64.786,297.577l181.215-109.8C246.001,187.777,246.001,467.129,246.001,467.14z M146.001,160.025v-20h220v20H146.001z
			 M398.023,463.084c0.111-3.094-28.039-3.066-29.925-2.867c-17.358,1.836-32.374,10.767-47.692,18.426
			c-18.096,9.048-36.716,6.447-54.405-1.98V187.777l181.215,109.8L398.023,463.084z"/>
        </g>
      </g>
    </svg>
  );
}
