import React from 'react';
import _ from 'lodash';
import {
  extractKnownSections,
  parseGauges,
  extractRiverName,
  extractIdZeme,
  extractRiverSectionsBySemaphore,
  extractRiverGuide,
} from '../datalayer/extractors';
import List from '@material-ui/core/List';
import { useRiverDetail, useCountryGauges } from '../datalayer/api';
import useReactRouter from 'use-react-router';
import LoadingInfo from '../ui-elements/LoadingInfo';
import ErrorInfo, { extractErrorMessage } from '../ui-elements/ErrorInfo';
import GaugeListItem from './GaugeListItem';
import { useSelectedSection, useSetSelectedSection } from '../utility/globalState';
import AppLayout from '../layout/AppLayout';
import useT from '../utility/useT';

export default function RiverWaterLevelsPage() {
  const { match, history } = useReactRouter();
  const { ID_reky } = match.params;

  const riverDetail = useRiverDetail(ID_reky);
  const name = extractRiverName(riverDetail);
  const section = useSelectedSection(ID_reky);

  const countryGauges = useCountryGauges(extractIdZeme(riverDetail));
  const gaugesData = extractRiverSectionsBySemaphore(countryGauges, ID_reky);
  const gauges = parseGauges(gaugesData);

  const subMenuItems = [];
  const t = useT();

  const handleClick = (index) => {
    history.push(`/river/${ID_reky}/sections/${index}`);
  };

  return (
    <AppLayout title={`${name} - ${t('Sections').toLocaleLowerCase()}`} subMenuItems={subMenuItems}>
      {countryGauges && countryGauges.state == 'pending' ? (
        <LoadingInfo message={t('LoadingGauges')} />
      ) : countryGauges && countryGauges.state == 'rejected' ? (
        <ErrorInfo message={countryGauges.error && extractErrorMessage(countryGauges)} />
      ) : (
        <List>
          {gauges.map((x, index) => (
            <GaugeListItem gauge={x} handleClick={() => handleClick(index)} section={section} />
          ))}
        </List>
      )}
    </AppLayout>
  );
}
