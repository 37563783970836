import React from 'react';

export default function River({ size = 30 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000">
      <g>
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
          <path d="M226.5,5014.5c-97.7-38.3-151.4-149.5-115-245.3c26.8-69,44.1-82.4,287.5-214.7c824.2-444.6,1481.6-989,1853.4-1531.4c245.3-362.2,360.3-668.9,381.4-1033.1c32.6-511.7-170.6-801.1-1067.6-1535.2C730.5-229.4,469.9-505.4,262.9-938.6c-128.4-270.3-157.2-415.9-159.1-780.1c0-467.7,65.2-743.7,276-1178.7c308.6-634.4,906.6-1190.2,1717.3-1596.6c247.2-122.7,672.7-291.3,736-291.3c101.6,0,189.7,90.1,191.7,193.6c0,101.6-55.6,159.1-216.6,218.5c-674.7,253-1174.9,559.7-1585.1,971.7c-193.6,195.5-304.7,337.3-421.7,540.5c-205.1,356.5-285.6,636.3-300.9,1040.7c-11.5,302.8,5.8,438.9,84.3,636.3c153.3,387.2,417.8,674.7,1194.1,1303.3C2750.7,907.1,3026.7,1300,3028.6,1903.8c1.9,983.2-724.5,1958.8-2073.8,2783C519.7,4953.1,310.8,5047.1,226.5,5014.5z" />
          <path d="M2628,4999.1c-32.6-13.4-69-42.2-80.5-65.2c-32.6-59.4-24.9-164.8,17.3-218.5c21.1-24.9,216.6-174.4,435.1-331.6c787.7-559.6,1140.4-841.4,1470-1169.1c665.1-663.2,923.8-1274.6,856.7-2018.2c-26.8-287.5-65.2-473.4-241.5-1161.5c-164.8-649.7-208.9-852.9-249.2-1178.7c-99.7-806.9,51.8-1278.4,582.6-1828.5c521.3-536.7,1345.5-1013.9,2482.1-1439.4c335.4-124.6,1044.6-345,1180.6-366.1c178.2-28.8,297.1,166.7,185.9,304.7c-36.4,44.1-109.2,72.8-483,184c-1360.8,410.1-2411.1,937.2-3005.3,1514.1c-216.6,208.9-343.1,373.7-442.8,573.1c-109.2,216.6-134.2,339.2-132.2,626.7c3.8,415.9,57.5,720.6,274.1,1558.2c207,805,249.2,1042.6,249.2,1429.8c0,1015.8-534.7,1830.4-1849.5,2821.3c-235.7,178.2-1023.5,737.9-1081,766.7C2735.3,5033.6,2706.6,5033.6,2628,4999.1z" />
          <path d="M8469.9,4958.9c-32.6-26.8-124.6-128.4-207-224.2c-80.5-97.8-161-174.4-178.2-174.4c-15.3,1.9-88.2,11.5-159.1,23c-203.2,30.7-381.4-7.7-561.6-120.7c-138-88.2-203.2-174.4-417.8-546.2c-410.1-713-665-1265-699.6-1518c-32.6-235.7,34.5-458.1,193.6-636.3c76.7-88.2,78.6-93.9,55.6-161c-371.8-1163.4-517.5-2110.2-494.5-3221.9c11.5-519.4,32.6-768.6,70.9-866.3c23-55.6,111.2-113.1,172.5-113.1s141.8,47.9,170.6,103.5c23,40.3,23,90.1,0,322c-32.6,339.2-34.5,1165.3-1.9,1491.1c70.9,690,212.8,1399.1,404.4,1993.3l57.5,182.1l178.2,1.9c207,1.9,327.7,42.2,490.7,162.9c124.6,93.9,212.8,224.2,302.8,446.6c93.9,235.8,203.2,450.4,477.2,941.1c268.3,479.2,306.7,582.7,291.3,795.4c-9.6,157.2-49.8,274.1-130.3,394.8l-55.6,80.5l42.2,53.7c23,30.7,86.3,107.3,141.8,170.6c151.4,176.3,178.3,228.1,159.1,312.4C8740.1,5001.1,8584.9,5056.6,8469.9,4958.9z M7981.1,4167.3c170.6-78.6,287.5-302.8,241.5-467.7c-11.5-40.2-128.4-264.5-258.8-498.3c-256.8-454.2-387.2-713-488.7-967.9c-36.4-86.2-86.3-182.1-111.2-212.7c-155.3-185.9-481.1-180.2-636.3,9.6c-122.7,149.5-126.5,293.2-11.5,573.1c168.7,415.9,688.1,1364.6,812.7,1487.3C7645.7,4203.7,7831.6,4234.4,7981.1,4167.3z" />
          <path d="M9108.1,2595.7C8354.9,2206.6,7615.1,1204.2,7203,12.1c-260.7-751.3-419.7-1730.7-427.4-2635.4c-3.8-352.7-3.8-356.5,42.2-406.3c95.8-101.6,251.1-78.6,312.4,46c26.8,53.7,34.5,116.9,34.5,316.3c0,312.4,28.8,699.6,76.7,1079.1c138,1046.5,444.7,1962.6,895.1,2666c101.6,157.2,383.3,538.6,398.7,538.6c3.8,0-51.7-122.7-122.7-272.2c-419.7-881.7-697.6-2035.5-806.9-3359.8c-47.9-594.2-70.9-1378.1-40.2-1483.5c30.7-111.2,197.4-159.1,297.1-86.3c67.1,51.8,72.8,78.6,86.3,410.2l13.4,297.1l111.2,277.9c331.6,820.3,841.4,1719.2,1541,2721.6c316.2,450.4,341.2,529,214.7,653.6c-65.2,67.1-178.2,74.7-297.1,19.2c-147.6-67.1-530.9-369.9-985.2-776.2l-256.8-230l13.4,74.8c26.8,143.7,201.3,690,297.1,929.6c230,580.7,435.1,939.2,778.1,1360.8c208.9,256.8,230,331.6,136.1,450.4c-36.4,44.1-61.3,55.6-147.6,63.2C9274.9,2672.4,9246.1,2666.6,9108.1,2595.7z M8855.1-304.2c-201.2-300.9-502.2-818.4-797.3-1366.6l-30.7-57.5l11.5,105.4c23,226.2,86.3,645.9,105.4,701.5c13.4,40.3,113.1,147.6,310.5,325.8c159.1,147.6,329.7,302.8,377.6,346.9c47.9,46,93.9,82.4,101.6,82.4C8941.4-166.2,8906.9-229.4,8855.1-304.2z" />
        </g>
      </g>
    </svg>
  );
}
