import _ from 'lodash';
import React from 'react';
import AppLayout from '../layout/AppLayout';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useReactRouter from 'use-react-router';
import { makeStyles } from '@material-ui/core/styles';
import useT from '../utility/useT';
import { useClassicalGuide, useSetClassicalGuide, useWatchPosition, useSetWatchPosition } from '../utility/globalState';
import Panel from '../ui-elements/Panel';
import Button from '@material-ui/core/Button';
import { Trans } from 'react-i18next';
import localforage from 'localforage';


const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
  },
}));

function CheckControl({ label, value, setter }) {
  const handleChange = (event) => {
    if (value == 'true') setter('false');
    else setter('true');
  };
  return (
    <FormControlLabel
      control={<Switch checked={value == 'true'} onChange={handleChange} color="primary" />}
      label={label}
    />
  );
}

export default function OfflineDataPage() {
  const classes = useStyles();
  const classicalGuide = useClassicalGuide();
  const setClassicalGuide = useSetClassicalGuide();
  const watchPosition = useWatchPosition();
  const setWatchPosition = useSetWatchPosition();

  function handleErase() {
    localforage.clear();
    localStorage.clear();
  }

  const t = useT();
  return (
    <AppLayout title={t('Settings')}>
      <Panel>
        <div className={classes.wrapper}>
          <CheckControl label={t('ShowClassicalGuide')} value={classicalGuide} setter={setClassicalGuide} />
        </div>
        <div className={classes.wrapper}>
          <CheckControl label={t('WatchPosition')} value={watchPosition} setter={setWatchPosition} />
        </div>
        <div className={classes.wrapper}>
          <Button onClick={handleErase} color="primary">
            <Trans>EraseOfflineData</Trans>
          </Button>
        </div>
      </Panel>
    </AppLayout>
  );
}
