import _ from 'lodash';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import CanoeIcon from '../icons/Canoe';
import useLocation from '../utility/useLocation';
import { distance, formatDistance, rangeIntersection } from '../utility/mathutil';
import RkmDetailDialog from '../rkmdetail/RkmDetailDialog';
import useRouterDialog from '../utility/useRouterDialog';
import {
  extractRiverGuide,
  extractRiverName,
  extractRkmKm,
  isItemShown,
  extractRiverId,
  extractNextRkmDetail,
  parseGauges,
  extractRiverSectionsBySemaphore,
  extractKnownSections,
  extractIdZeme,
} from '../datalayer/extractors';
import RkmListItem from './RkmListItem';
import { makeStyles } from '@material-ui/core/styles';
import { addCurrentLocationToGuide, guideHasGps } from '../datalayer/guideGps';
import LocationFab from '../maps/LocationFab';
import useStateDialog from '../utility/useStateDialog';
import ChooseFromListDialog from '../ui-elements/ChooseFromListDialog';
import useT from '../utility/useT';
import { useSetSelectedSection } from '../utility/globalState';
import { useCountryGauges } from '../datalayer/api';
import { Semaphore } from './GaugeListItem';

const useStyles = makeStyles((theme) => ({
  separator: {
    color: 'gray',
    backgroundColor: '#EEE',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  current: {
    backgroundColor: '#CFC',
  },
  currentIconWrapper: {
    margin: 'auto',
  },
  difficulty: {
    marginRight: '10px',
  },
  semaphore: {
    position: 'relative',
    top: '2px',
  },
}));

export default function RiverGuide({ riverDetail, useImageGuide, section, shownObjects }) {
  const setSection = useSetSelectedSection(extractRiverId(riverDetail));
  const location = useLocation();
  const t = useT();
  const guide = extractRiverGuide(riverDetail, section);
  let hasGps = false;
  const [currentRkmDetail, setCurrentRkmDetail] = React.useState(null);
  const [openDetails, detailsDialogProps] = useRouterDialog('guideDetail');
  const [selectedPlace, setSelectedPlace] = React.useState(null);
  const classes = useStyles();
  const currentLocationRef = React.useRef(null);
  const [openItemMenu, itemMenuProps] = useStateDialog();
  const countryGauges = useCountryGauges(extractIdZeme(riverDetail));
  const sectionsBySemaphore = React.useMemo(
    () => parseGauges(extractRiverSectionsBySemaphore(countryGauges, extractRiverId(riverDetail))),
    [countryGauges, riverDetail]
  );
  const knownSections = React.useMemo(() => extractKnownSections(riverDetail), [riverDetail]);

  let guideShown = guide.filter(isItemShown(shownObjects));

  if (guideHasGps(guideShown) && location && location.latitude && location.longitude) {
    hasGps = true;
    guideShown = addCurrentLocationToGuide(guideShown, location);
  }

  const handleContextMenu = (e, rkmDetail) => {
    e.preventDefault();
    setCurrentRkmDetail(rkmDetail);
    // @ts-ignore
    openItemMenu();
  };

  const menuItems = [
    {
      text: t('SetBegin'),
      icon: SubdirectoryArrowRightIcon,
      onClick: () =>
        setSection({
          ...section,
          begin: extractRkmKm(currentRkmDetail),
        }),
    },
    {
      text: t('SetEnd'),
      icon: SubdirectoryArrowLeftIcon,
      onClick: () =>
        setSection({
          ...section,
          end: extractRkmKm(currentRkmDetail),
        }),
    },
  ];

  return (
    <>
      <RkmDetailDialog {...detailsDialogProps} rkmDetail={selectedPlace} riverDetail={riverDetail} />
      <List>
        {_.flatten(
          guideShown.map((item, index) => {
            const rkm1 = extractRkmKm(item);
            const rkm2 = index < guideShown.length - 1 ? extractRkmKm(guideShown[index + 1]) : undefined;
            const rkmMiddle = rkm2 != null ? (rkm1 + rkm2) / 2 : undefined;
            const difficultySection = knownSections.find((x) => rkmMiddle <= x.range.begin && rkmMiddle >= x.range.end);
            const { difficulty } = difficultySection || {};
            return [
              item.currentLocation ? (
                <ListItem className={classes.current}>
                  <div className={classes.currentIconWrapper} ref={currentLocationRef}>
                    <CanoeIcon size={50} />
                  </div>
                </ListItem>
              ) : (
                <RkmListItem
                  key={item.km}
                  rkmDetail={item}
                  useImageGuide={useImageGuide}
                  onContextMenu={(e) => handleContextMenu(e, item)}
                  onClick={() => {
                    setSelectedPlace(item);
                    // @ts-ignore
                    openDetails();
                  }}
                />
              ),
              index < guideShown.length - 1 ? (
                <ListItem className={classes.separator}>
                  <div>+ {formatDistance(rkm1, rkm2)}</div>
                  <div>
                    <span className={classes.difficulty}>{difficulty}</span>
                    <Semaphore
                      gauge={sectionsBySemaphore.find((x) => rkmMiddle <= x.range.begin && rkmMiddle >= x.range.end)}
                      size={15}
                      className={classes.semaphore}
                    />
                  </div>
                </ListItem>
              ) : null,
            ];
          })
        )}
      </List>
      <LocationFab
        hasGps={hasGps}
        onClickLocation={() => {
          if (currentLocationRef && currentLocationRef.current) {
            currentLocationRef.current.scrollIntoView({ block: 'center' });
          }
        }}
      />
      <ChooseFromListDialog items={menuItems} {...itemMenuProps} />
    </>
  );
}
