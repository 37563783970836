import _ from 'lodash';
import useReactRouter from 'use-react-router';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import RkmListItem from './RkmListItem';
import AppLayout from '../layout/AppLayout';
import LoadingInfo from '../ui-elements/LoadingInfo';
import useT from '../utility/useT';

function RiverGuideDialogPage({ title, onClose = undefined, guide, onChoose }) {
  const t = useT();
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    window.setTimeout(() => setVisible(true), 1);
  }, []);

  return (
    <AppLayout title={title}>
      {!visible && <LoadingInfo message={t('LoadingList')} />}
      {visible && (
        <List>
          {guide.map((item) => (
            <RkmListItem
              key={item.km}
              rkmDetail={item}
              onClick={() => {
                onChoose(item);
                if (onClose) onClose();
              }}
            />
          ))}
        </List>
      )}
    </AppLayout>
  );
}

export default function RiverGuideDialog({ title, onClose = undefined, open = false, guide, riverDetail, onChoose }) {
  const { history } = useReactRouter();
  React.useEffect(() => {
    if (open && !guide) history.goBack();
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      {open && <RiverGuideDialogPage title={title} onChoose={onChoose} onClose={onClose} guide={guide} />}
    </Dialog>
  );
}
