import React from 'react';
import _ from 'lodash';
import PointsMap from '../maps/PointsMap';
import RkmDetailDialog from '../rkmdetail/RkmDetailDialog';
import useRouterDialog from '../utility/useRouterDialog';
import { extractMapPointsFromGuide, extractRiverName, extractRiverGuide, isItemShown } from '../datalayer/extractors';
import RiverDetailWrapper from './RiverDetailWrapper';

export default function RiverMap({ riverDetail, shownObjects, section }) {
  const [openDetails, detailsDialogProps] = useRouterDialog('mapDetail');
  const [selectedPlace, setSelectedPlace] = React.useState(null);

  const guide = extractRiverGuide(riverDetail, section);
  const points = extractMapPointsFromGuide(guide, shownObjects);

  return (
    <>
      <RkmDetailDialog {...detailsDialogProps} rkmDetail={selectedPlace} riverDetail={riverDetail} />
      <RiverDetailWrapper riverDetail={riverDetail}>
        <PointsMap
          points={points}
          extractPoint={(point) => point.gps}
          extractImage={(point) => point.icon}
          onMarkerClick={(point) => {
            setSelectedPlace(point.rkmDetail);
            // @ts-ignore
            openDetails();
          }}
        />
      </RiverDetailWrapper>
    </>
  );
}
