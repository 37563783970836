import _ from 'lodash';
import React from 'react';
import AppLayout from '../layout/AppLayout';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import localforage from 'localforage';
import useReactRouter from 'use-react-router';
import RiverList from '../riverlist/RiverList';
import { useRiverDb } from '../datalayer/api';
import useLanguage from '../utility/useLanguage';
import useT from '../utility/useT';

export default function OfflineDataPage() {
  const { history, match } = useReactRouter();

  const [cachedRivers, setCachedRivers] = React.useState([]);
  const [reloadCounter, setReloadCounter] = React.useState(0);
  const language = useLanguage();
  const t = useT();

  const { Staty, Reky } = useRiverDb();
  const { ID_zeme } = match.params;

  async function handleLoadOfflineKeys() {
    const keys = await localforage.keys();
    const riverIdList = keys
      .map((x) => x.match(`river-${language}-(\\d+)`))
      .filter((x) => x)
      .map((x) => parseInt(x[1]));

    setCachedRivers(Reky.filter((x) => riverIdList.includes(x.ID_reky)));
  }

  React.useEffect(() => {
    handleLoadOfflineKeys();
  }, [reloadCounter, language, Reky]);

  function handleClearOffline() {
    localforage.clear();
    setReloadCounter((x) => x + 1);
  }

  const subMenuItems = [];

  subMenuItems.push({
    label: t('EraseOfflineData'),
    handler: handleClearOffline,
  });

  return (
    <AppLayout title={t('OfflineData')} subMenuItems={subMenuItems} showLanguageSelector>
      <RiverList rivers={cachedRivers} countries={Staty} />
    </AppLayout>
  );
}
