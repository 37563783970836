import React from 'react';
import AppLayout from '../layout/AppLayout';
import Grid from '@material-ui/core/Grid';
import useReactRouter from 'use-react-router';
import _ from 'lodash';
import { useRiverDb } from '../datalayer/api';
import LoadingInfo from '../ui-elements/LoadingInfo';
import ErrorInfo, { extractErrorMessage } from '../ui-elements/ErrorInfo';
import { makeStyles } from '@material-ui/core/styles';
import Flag from '../icons/Flag';
import NativeRouterSearchField from '../ui-elements/NativeRouterSearchField';
import { getSearchValue } from '../ui-elements/SearchField';
import RiverList from '../riverlist/RiverList';
import containsText from '../utility/containsText';
import useT from '../utility/useT';
import InfoBox from '../ui-elements/InfoBox';
import { useSetWarrantyClosed, useWarrantyClosed } from '../utility/globalState';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url("${process.env.REACT_APP_ROUTE_BASE}/images/homebg.jpg")`,
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
  },
  tileWrapper: {
    position: 'relative',
    height: '120px',
  },
  tileBackground: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    // color: 'white',
    margin: '2px',
    // @ts-ignore
    backgroundColor: theme.tileBackgroundColor,
    // @ts-ignore
    opacity: theme.tileBackgroundOpacity,
    // backgroundColor: '#22223377',

    // border: '1px solid #555'
  },
  tileContent: {
    position: 'absolute',
    display: 'flex',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    cursor: 'pointer',
    '&:hover': {
      // @ts-ignore
      backgroundColor: theme.tileBackgroundColor,
    },
  },
  tileInner: {
    margin: 'auto',
    textAlign: 'center',
    flex: 1,
  },
  searchbox: {
    fontSize: '1rem',
    padding: '15px',
    backgroundColor: '#FFFFFFCC',
    width: '70vw',
    // margin: '0 15vw',
    outline: 'none',
    border: '0px solid',
    borderRadius: '10px',
  },
  searchboxWrapper: {
    padding: '5vh 0px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  searchResultWrapper: {
    // @ts-ignore
    backgroundColor: theme.tileBackgroundColor,
    // @ts-ignore
    opacity: theme.tileBackgroundOpacity,
  },
  counts: {
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // margin: '0px 5px',
  }
}));

function CountryTile({ country }) {
  const classes = useStyles();
  const { history } = useReactRouter();
  const t = useT();

  return (
    <Grid item xs={6} sm={4} md={3} lg={2} onClick={() => history.push(`/country/${country.ID_zeme}`)}>
      <div className={classes.tileWrapper}>
        <div className={classes.tileBackground}></div>
        <div className={classes.tileContent}>
          <div className={classes.tileInner}>
            <Flag url={country.Url} />
            <div>{country.Nazev}</div>
            {/* <RiverIcon /> {country.riverCount}
          <CreekIcon /> {country.creekCount} */}
            <div className={classes.counts}>
              {_.compact([
                country.riverCount > 0 ? `${t('rivers')}: ${country.riverCount}` : null,
                country.creekCount > 0 ? `${t('creeks')}: ${country.creekCount}` : null,
                // country.channelCount > 0 ? `${t('channels')}: ${country.channelCount}` : null,
              ]).join(', ')}
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default function CountryListPage() {
  const classes = useStyles();
  const { history } = useReactRouter();
  const { Staty, Reky: rivers, state, error } = useRiverDb();
  const warrantyClosed = useWarrantyClosed();
  const setWarrantyClosed = useSetWarrantyClosed();
  const countries = Staty.map((x) => ({
    ...x,
    riverCount: rivers.filter((river) => river.ID_zeme == x.ID_zeme && river.Reka == 1).length,
    creekCount: rivers.filter((river) => river.ID_zeme == x.ID_zeme && river.Reka == 0).length,
    channelCount: rivers.filter((river) => river.ID_zeme == x.ID_zeme && river.Reka == 2).length,
  })).filter((x) => x.riverCount > 0 || x.creekCount > 0 || x.channelCount > 0);
  const t = useT();

  const [filter, setFilter] = React.useState(getSearchValue(history, 'homepage'));

  const handleRunFilter = (filter) => {
    setFilter(filter);
  };

  return (
    <AppLayout title="Raft.cz" mainIconMeaning={filter ? 'back' : 'menu'} showLanguageSelector>
      {state == 'pending' ? (
        <LoadingInfo message={t('LoadingList')} />
      ) : state == 'rejected' ? (
        <ErrorInfo message={extractErrorMessage(error)} />
      ) : (
        <div className={classes.root}>
          <div className={classes.searchboxWrapper}>
            <NativeRouterSearchField
              routerStateField="homepage_search"
              onRunFilter={handleRunFilter}
              className={classes.searchbox}
              placeholder={t('SearchRiver')}
            />
          </div>
          {warrantyClosed == 'false' && (
            <InfoBox message={t('WarrantyInfo')} onClose={() => setWarrantyClosed('true')} />
          )}
          {filter ? (
            <div className={classes.searchResultWrapper}>
              <RiverList rivers={rivers.filter((river) => containsText(filter, river.Nazev))} countries={Staty} />
            </div>
          ) : (
            <Grid container>
              {countries.map((country) => (
                <CountryTile country={country} />
              ))}
            </Grid>
          )}
        </div>
      )}
    </AppLayout>
  );
}
