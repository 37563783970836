import React from 'react';

export default function Flag({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 1000 600">
      <path fill="#c1272d" d="M0 0h1000v600H0z" />
      <path fill="#006a44" d="M0 0h1000v400H0z" />
      <path fill="#fdb913" d="M0 0h1000v200H0z" />{' '}
      {/* <rect width="1280" height="800" fill="none" stroke-width="17" stroke="black" id="rect2213" /> */}
    </svg>
  );
}
