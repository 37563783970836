import React from 'react';
import LoadingInfo from '../ui-elements/LoadingInfo';
import ErrorInfo, { extractErrorMessage } from '../ui-elements/ErrorInfo';
import useT from '../utility/useT';

export default function AsyncDataWrapper({ dataHolder, loadingMessage, children }) {
  return dataHolder && dataHolder.state == 'pending' ? (
    <LoadingInfo message={loadingMessage} />
  ) : dataHolder && dataHolder.state == 'rejected' ? (
    <ErrorInfo message={dataHolder.error && extractErrorMessage(dataHolder.error)} />
  ) : (
    children
  );
}

export function combineDataHolders(...holders) {
  if (holders.find((x) => x.state == 'rejected')) return { state: 'rejected' };
  if (holders.find((x) => x.state == 'pending')) return { state: 'pending' };
  return { state: 'loaded' };
}
