import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import RiverListPage from './riverlist/RiverListPage';
import RiverDetailPage from './riverdetail/RiverDetailPage';
import CountryListPage from './countrylist/CountryListPage';
import OfflineDataPage from './offline-data/OfflineDataPage';
import RiverMapPage from './riverdetail/RiverMapPage';
import RiverGuidePage from './riverdetail/RiverGuidePage';
import RiverGaugesPage from './riverdetail/RiverGaugesPage';
import RiverWaterLevelsPage from './riverdetail/RiverWaterLevelsPage';
import SectionDetailPage from './riverdetail/SectionDetailPage';
import RiverDifficultyPage from './riverdetail/RiverDifficultyPage';
import RiverDescriptionPage from './riverdetail/RiverDescriptionPage';
import SettingsPage from './settings/SettingsPage';
import { RiverCommentsPage, RiverLogsPage, RiverArticlesPage } from './riverdetail/RiverAdditionalInfoPage';
import AboutPage from './settings/AboutPage';
import ExplanationsPage from './settings/ExplanationsPage';

function AppRoutes() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_ROUTE_BASE || ''}>
      <Switch>
        <Route path="/offline" component={OfflineDataPage} />
        <Route path="/river/:ID_reky/map" component={RiverMapPage} />
        <Route path="/river/:ID_reky/guide" component={RiverGuidePage} />
        <Route path="/river/:ID_reky/comments" component={RiverCommentsPage} />
        <Route path="/river/:ID_reky/description" component={RiverDescriptionPage} />
        <Route path="/river/:ID_reky/logs" component={RiverLogsPage} />
        <Route path="/river/:ID_reky/articles" component={RiverArticlesPage} />
        <Route path="/river/:ID_reky/difficulty" component={RiverDifficultyPage} />
        <Route path="/river/:ID_reky/gauges" component={RiverGaugesPage} />
        <Route path="/river/:ID_reky/water-level" component={RiverWaterLevelsPage} />
        <Route path="/river/:ID_reky/sections/:index" component={SectionDetailPage} />
        <Route path="/river/:ID_reky" component={RiverDetailPage} />
        <Route path="/country/:ID_zeme" component={RiverListPage} />
        <Route path="/index.html" component={CountryListPage} />
        <Route path="/settings" component={SettingsPage} />
        <Route path="/explanations" component={ExplanationsPage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/" exact component={CountryListPage} />

        {/* <Route exact path={`${process.env.REACT_APP_ROUTE_BASE || ''}/`} component={TestPage} />
        <Route exact path={`${process.env.REACT_APP_ROUTE_BASE || ''}/index.html`} component={TestPage} /> */}
      </Switch>
    </BrowserRouter>
  );
}

//const AppRoutesComposed = withRouter(AppRoutes);

export default AppRoutes;
