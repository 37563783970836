import { useTranslation } from 'react-i18next';

export const availableLanguages = [
  { title: 'English', code: 'en' },
  { title: 'Česky', code: 'cs' },
  { title: 'Slovensky', code: 'sk' },
  { title: 'Polski', code: 'pl' },
  { title: 'Deutch', code: 'de' },
];

export function getLanguage(i18n) {
  const { languages } = i18n;
  for (const lang of languages || []) {
    for (const avail of availableLanguages) {
      if (lang === avail.code) return lang;
    }
  }
  return 'cs';
}

export default function useLanguage() {
  const [t, i18n] = useTranslation();
  return getLanguage(i18n);
}
