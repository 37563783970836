import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export function getLocalizedMessage(message, t) {
  if (_.isString(message) && message.startsWith('t:')) return t(message.substring(2));
  return message;
}

export default function useT() {
  const [t] = useTranslation();
  return t;
}
