import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  linkitem: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    margin: theme.spacing(1),
  },
}));

export default function IconLink({ Icon, children, link }) {
  const classes = useStyles();
  return (
    <a className={classes.linkitem} href={link} target='_blank' rel="noopener noreferrer">
      <Icon className={classes.icon} />
      {children}
    </a>
  );
}
