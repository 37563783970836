import _ from 'lodash';
import React from 'react';
import AppLayout from '../layout/AppLayout';
// import ReactMarkdown from 'react-markdown';
import MailIcon from '@material-ui/icons/Mail';
import LanguageIcon from '@material-ui/icons/Language';
import { makeStyles } from '@material-ui/core/styles';
import useT from '../utility/useT';
import InfoBox from '../ui-elements/InfoBox';
import Panel from '../ui-elements/Panel';
import IconLink from '../ui-elements/IconLink';
import explanations from '../translations/explanations';
import AsyncDataWrapper from '../ui-elements/AsyncDataWrapper';
import useLanguage from '../utility/useLanguage';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
  },
}));

export default function ExplanationsPage() {
  const t = useT();
  const classes = useStyles();
  const language = useLanguage();
  return (
    <AppLayout title={t('Explanations')}>
      <div dangerouslySetInnerHTML={{ __html: explanations[language] }} className={classes.wrapper} />
    </AppLayout>
  );
}
