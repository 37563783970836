import React from 'react';
import _ from 'lodash';
import AppLayout from '../layout/AppLayout';
import useReactRouter from 'use-react-router';
import { useRiverDetail, useCountryGauges } from '../datalayer/api';
import {
  extractRiverName,
  extractFullRiverSection,
  extractRiverBegins,
  extractRiverEnds,
  extractDifficultyCoverage,
  extractRiverSemaphoreByRiver,
  extractIdZeme,
  extractRiverSectionsBySemaphore,
  extractArticleCount,
  extractLogCount,
  extractCommentCount,
  extractRiverArticles,
  extractRiverLogs,
  extractRiverComments,
  extractRiverGuide,
  extractMapPointsFromGuide,
  extractRkmKm,
  extractRiverImage,
  extractKnownSections,
  parseGauges,
} from '../datalayer/extractors';
import { useSelectedSection, useSetSelectedSection } from '../utility/globalState';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import KmSelector from '../ui-elements/KmSelector';
import NameValueView from '../ui-elements/NameValueView';
import { roundKm } from '../utility/mathutil';
import MapIcon from '@material-ui/icons/Map';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CommentIcon from '@material-ui/icons/Comment';
import CreateIcon from '@material-ui/icons/Create';
import ListIcon from '@material-ui/icons/List';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import InfoIcon from '@material-ui/icons/Info';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { findNearestCoordinates } from '../datalayer/guideGps';
import { DriveToButton } from '../rkmdetail/GpsView';
import localforage from 'localforage';
import RiverDetailWrapper from './RiverDetailWrapper';
import useT from '../utility/useT';
import { GaugeAvatar } from './GaugeListItem';
import Chip from '@material-ui/core/Chip';
import { Trans } from 'react-i18next';
import FeedbackDialog from '../feedback/FeedbackDialog';
import useRouterDialog from '../utility/useRouterDialog';
import RiverGuideDialog from './RiverGuideDialog';
import AvatarBar from '../ui-elements/AvatarBar';
import AvatarButton from '../ui-elements/AvatarButton';
import { useSnackbar } from 'notistack';
import useOnlineStatus from '../utility/useOnlineStatus';
import { drawerWidth } from '../utility/useViewportWidth';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
    // backgroundColor: theme.palette.background.paper,
  },
  header: {
    //padding: theme.spacing(2),
    fontSize: '1.3rem',
    // backgroundColor: theme.palette.background.paper,
  },
  root: {
    position: 'relative',
  },
  paper: {
    backgroundColor: '#CCD',
    paddingTop: '5px',
    // paddingTop: theme.spacing(1),
  },
  paperInner: {
    backgroundColor: '#EEE',
    display: 'flex',
    padding: '10px 0',
  },
  paperInnerButton: {
    backgroundColor: '#EEE',
    display: 'flex',
    padding: '10px 0',

    cursor: 'pointer',
    '&:hover': {
      // @ts-ignore
      backgroundColor: '#DDD',
    },
    // paddingTop: theme.spacing(1),
  },
  iconWrapper: {
    marginLeft: theme.spacing(2),
    marginTop: 'auto',
    marginBottom: 'auto',
    // margin: 'auto 10px',
    // marginRight: '20px',
  },
  itemBodyWrapper: {
    marginLeft: theme.spacing(2),
  },
  selectedSectionBody: {
    marginTop: theme.spacing(2),
  },
  kmSelectorWrap: {
    display: 'flex',
    marginBottom: theme.spacing(2),

    // justifyContent: 'space-between',
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(90vw - ${drawerWidth}px)`,
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: '90vw',
    // },
  },
  driveToButton: {
    marginLeft: '10px',
  },
  sectionList: {
    display: 'flex',
    // border: '1px solid #aaa',
    paddingTop: '5px',
    // paddingLeft: 0,
  },
  bold: {
    fontWeight: 'bold',
  },
  headerImage: {
    backgroundImage: `url("${process.env.REACT_APP_ROUTE_BASE}/images/feature.jpg")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '160px',
  },
}));

function enrichKmOptions(options, km) {
  if (!_.find(options, (x) => x.km == km)) {
    return _.sortBy([...options, { km, name: '' }], (x) => -x.km);
  }
  return options;
}

function OverviewSectionDesign({
  title,
  children = undefined,
  pathBase = undefined,
  page = undefined,
  Icon = undefined,
  button = false,
}) {
  const classes = useStyles();
  const { history } = useReactRouter();
  const handleClick = () => {
    if (page) {
      history.push(`${pathBase}/${page}`);
    }
  };
  return (
    // <ListItem>
    //   {Icon && (
    //     <ListItemIcon>
    //       <Icon />
    //     </ListItemIcon>
    //   )}
    //   {children}
    // </ListItem>
    <div onClick={handleClick} className={classes.paper}>
      <div className={button ? classes.paperInnerButton : classes.paperInner}>
        {Icon && (
          <div className={classes.iconWrapper}>
            <Icon />
          </div>
        )}
        <div className={classes.itemBodyWrapper}>
          <div className={classes.header}>{title}</div>
          {children}
        </div>
      </div>
    </div>
  );
}

function SelectedSectionOverview({ ID_reky, riverDetail }) {
  const classes = useStyles();
  const t = useT();
  const begins = extractRiverBegins(riverDetail, t);
  const ends = extractRiverEnds(riverDetail, t);
  const section = useSelectedSection(ID_reky);
  const setSection = useSetSelectedSection(ID_reky);
  const coverage = extractDifficultyCoverage(riverDetail, section);
  const [openGuideDialog, guideDialogProps] = useRouterDialog('guideDialog');
  const [openedGuide, setOpenedGuide] = React.useState(null);
  const [chooseGuideField, setChooseGuideField] = React.useState(null);

  const wholeRiver = React.useMemo(() => extractFullRiverSection(riverDetail), [riverDetail]);

  React.useEffect(() => {
    // @ts-ignore
    if (section.begin == 0 && section.end == 0) {
      setSection(extractFullRiverSection(riverDetail));
    }
  }, [riverDetail]);

  const beginCoordinates = React.useMemo(() => findNearestCoordinates(riverDetail, section.begin, 'zacatek'), [
    riverDetail,
    section.begin,
  ]);
  const endCoordinates = React.useMemo(() => findNearestCoordinates(riverDetail, section.end, 'konec'), [
    riverDetail,
    section.end,
  ]);

  return (
    <OverviewSectionDesign
      title={
        <>
          {t('SelectedSection')}{' '}
          {section && section.begin == wholeRiver.begin && section.end == wholeRiver.end ? (
            <Chip label={t('WholeRiver')} />
          ) : (
            <Chip color="primary" label={t('SetWholeRiver')} onClick={() => setSection(wholeRiver)} />
          )}
        </>
      }
    >
      <div className={classes.selectedSectionBody}>
        <div className={classes.kmSelectorWrap}>
          <KmSelector
            options={enrichKmOptions(
              begins.filter((x) => x.km == null || x.km > section.end),
              section.begin
            )}
            label={t('Begin')}
            section={section}
            setSection={setSection}
            field="begin"
            onChoose={() => {
              const guide = extractRiverGuide(riverDetail).filter((x) => extractRkmKm(x) > section.end);
              setOpenedGuide(guide);
              setChooseGuideField('begin');
              // @ts-ignore
              openGuideDialog();
            }}
          />
          <DriveToButton coordinates={beginCoordinates} className={classes.driveToButton} />
        </div>
        <div className={classes.kmSelectorWrap}>
          <KmSelector
            options={enrichKmOptions(
              ends.filter((x) => x.km == null || x.km < section.begin),
              section.end
            )}
            label={t('End')}
            section={section}
            setSection={setSection}
            field="end"
            onChoose={() => {
              const guide = extractRiverGuide(riverDetail).filter((x) => extractRkmKm(x) < section.begin);
              setOpenedGuide(guide);
              setChooseGuideField('end');
              // @ts-ignore
              openGuideDialog();
            }}
          />
          <DriveToButton coordinates={endCoordinates} className={classes.driveToButton} />
        </div>

        <NameValueView name={t('SectionLength')} value={`${roundKm(section.begin - section.end)} km`} />
        {_.keys(coverage).map((difficulty) => (
          <NameValueView name={difficulty} value={`${roundKm(coverage[difficulty])} km`} />
        ))}
      </div>
      <RiverGuideDialog
        {...guideDialogProps}
        riverDetail={riverDetail}
        guide={openedGuide}
        title={chooseGuideField == 'begin' ? t('SetBegin') : t('SetEnd')}
        onChoose={(rkm) => {
          setSection({
            ..._.pick(section, ['begin', 'end']),
            [chooseGuideField]: extractRkmKm(rkm),
          });
        }}
      />
    </OverviewSectionDesign>
  );
}

function getSectionTitle(section, riverDetail, t) {
  const full = extractFullRiverSection(riverDetail);
  if (section.begin == full.begin && section.end == full.end) {
    return t('WholeRiver');
  }
  const begins = extractRiverBegins(riverDetail, t);
  const ends = extractRiverBegins(riverDetail, t);

  const begin = begins.find((x) => x.km == section.begin);
  const end = ends.find((x) => x.km == section.end);

  return [
    (begin && begin.name) || `km ${roundKm(section.begin)}`,
    (end && end.name) || `km ${roundKm(section.end)}`,
  ].join(' - ');
}

function AdditionalInfoSummary({ title, page, pathBase, icon, riverDetail, extractCount, extractItems }) {
  const count = extractCount(riverDetail);
  if (count > 0) {
    return (
      <OverviewSectionDesign button title={title} page={page} pathBase={pathBase} Icon={icon}>
        <Trans>ItemCountDownloaded</Trans>: {extractItems(riverDetail).length}, <Trans>ItemCountTotal</Trans>: {count}
      </OverviewSectionDesign>
    );
  }
  return null;
}

export function RiverDetailCore({ ID_reky, riverDetail, pathBase, openFeedback, removeFromOffline }) {
  const section = useSelectedSection(ID_reky);
  const setSection = useSetSelectedSection(ID_reky);
  const { history } = useReactRouter();
  const classes = useStyles();
  const t = useT();
  const countryGauges = useCountryGauges(extractIdZeme(riverDetail));
  const sectionsBySemaphore = extractRiverSectionsBySemaphore(countryGauges, ID_reky);
  const online = useOnlineStatus();

  const sectionTitle = React.useMemo(() => getSectionTitle(section, riverDetail, t), [section, riverDetail]);
  const guide = React.useMemo(() => extractRiverGuide(riverDetail, section), [riverDetail, section]);
  const fullGuide = extractRiverGuide(riverDetail);
  const isGuide = fullGuide.length > 0;
  const mapPoints = React.useMemo(() => extractMapPointsFromGuide(guide), [guide]);

  const riverImage = React.useMemo(() => extractRiverImage(riverDetail), [riverDetail]);

  const theme = useTheme();

  return (
    <div className={classes.root}>
      <div className={classes.headerImage}>
        <div
          className={classes.headerImage}
          style={
            riverImage
              ? {
                  backgroundImage: `url("${riverImage}")`,
                  position: 'absolute',
                }
              : undefined
          }
        />
      </div>

      <AvatarBar usage="river">
        {online && <AvatarButton Icon={EditIcon} onClick={openFeedback} color={theme.palette.primary.main} />}
        {online && <AvatarButton Icon={DeleteIcon} onClick={removeFromOffline} color={theme.palette.primary.main} />}
      </AvatarBar>

      {isGuide && (
        <OverviewSectionDesign button title={t('RiverGuide')} page="guide" pathBase={pathBase} Icon={ListIcon}>
          {sectionTitle}
        </OverviewSectionDesign>
      )}
      {mapPoints.length > 0 && (
        <OverviewSectionDesign button title={t('Map')} page="map" pathBase={pathBase} Icon={MapIcon}>
          {sectionTitle}
        </OverviewSectionDesign>
      )}
      <OverviewSectionDesign button title={t('Sections')} page="difficulty" pathBase={pathBase} Icon={KeyboardTabIcon}>
        <div>{t('ByDifficulty')}</div>
        {section && section.difficultySectionName && (
          <div className={classes.bold}>{section.difficultySectionName}</div>
        )}
      </OverviewSectionDesign>
      {sectionsBySemaphore.length > 0 && (
        <OverviewSectionDesign button title={t('Sections')} page="gauges" pathBase={pathBase} Icon={DirectionsBoatIcon}>
          <div>{t('ByWaterLevel')}</div>
          {
            <div className={classes.sectionList}>
              {sectionsBySemaphore.map((gauge) => (
                <GaugeAvatar gauge={gauge} selectedSection={section} />
              ))}
            </div>
          }
        </OverviewSectionDesign>
      )}
      {isGuide && <SelectedSectionOverview ID_reky={ID_reky} riverDetail={riverDetail}></SelectedSectionOverview>}
      <OverviewSectionDesign
        button
        title={t('CurrentWaterLevel')}
        page="water-level"
        pathBase={pathBase}
        Icon={ShowChartIcon}
      ></OverviewSectionDesign>
      <OverviewSectionDesign
        button
        title={t('Conditions')}
        page="description"
        pathBase={pathBase}
        Icon={InfoIcon}
      ></OverviewSectionDesign>
      <AdditionalInfoSummary
        riverDetail={riverDetail}
        page="articles"
        pathBase={pathBase}
        icon={TextFieldsIcon}
        title={t('Articles')}
        extractItems={extractRiverArticles}
        extractCount={extractArticleCount}
      />
      <AdditionalInfoSummary
        riverDetail={riverDetail}
        page="logs"
        pathBase={pathBase}
        icon={CreateIcon}
        title={t('RiverLogs')}
        extractItems={extractRiverLogs}
        extractCount={extractLogCount}
      />
      <AdditionalInfoSummary
        riverDetail={riverDetail}
        page="comments"
        pathBase={pathBase}
        icon={CommentIcon}
        title={t('CommentsLong')}
        extractItems={extractRiverComments}
        extractCount={extractCommentCount}
      />
    </div>
  );
}

export default function RiverDetailPage() {
  const { history, match } = useReactRouter();
  const { ID_reky } = match.params;
  const t = useT();
  const [openFeedback, feedbackDialogProps] = useRouterDialog('feedbackDetail');
  const { enqueueSnackbar } = useSnackbar();
  const online = useOnlineStatus();

  const riverDetail = useRiverDetail(ID_reky);
  const pathBase = `/river/${ID_reky}`;
  const name = extractRiverName(riverDetail);

  const handleRemoveFromOffline = async () => {
    const keys = await localforage.keys();
    for (const key of keys) {
      const m = key.match(/(river|gauge)-..-(\d+)/);
      if (m && m[2] == ID_reky) await localforage.removeItem(key);
    }
    enqueueSnackbar(t('RiverRemovedFromOffline'), { variant: 'warning' });
    // await localforage.removeItem(`river-${ID_reky}`);
    // await localforage.removeItem(`gauge-${ID_reky}`);
    history.goBack();
  };

  const subMenuItems = [];

  if (online) {
    subMenuItems.push(
      {
        label: t('RemoveFromOffline'),
        handler: handleRemoveFromOffline,
      },
      {
        label: t('SendFeedback'),
        handler: openFeedback,
      }
    );
  }

  return (
    <AppLayout title={name} subMenuItems={subMenuItems}>
      <RiverDetailWrapper riverDetail={riverDetail}>
        <RiverDetailCore
          ID_reky={ID_reky}
          pathBase={pathBase}
          riverDetail={riverDetail}
          openFeedback={openFeedback}
          removeFromOffline={handleRemoveFromOffline}
        />
      </RiverDetailWrapper>
      <FeedbackDialog riverDetail={riverDetail} {...feedbackDialogProps} />
    </AppLayout>
  );
}
