import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useSetShownObjects, useShownObjects } from '../utility/globalState';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '18pt',
  },
}));

function ItemImage({ name }) {
  const classes = useStyles();
  if (name == 'zacatek_konec') return <ItemImage name="konec_zacatek" />;
  if (name == '_other') return <span className={classes.icon}>?</span>;
  return <img width={20} height={20} src={`${process.env.REACT_APP_ROUTE_BASE}/images/${name}_k.gif`} alt={name} />;
}

function Item({ title, name, shownObjects, setShownObjects }) {
  const handleClick = () => {
    setShownObjects({
      ...shownObjects,
      [name]: !shownObjects[name],
    });
  };

  return (
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <ItemImage name={name} />
      </ListItemIcon>
      <ListItemText primary={title} />
      <Checkbox edge="start" checked={shownObjects[name]} tabIndex={-1} disableRipple />
    </ListItem>
  );
}

function ObjectFilterDialog({ onClose = undefined, ...other }) {
  const t = useT();
  function handleClose() {
    onClose();
  }
  const shownObjects = useShownObjects();
  const setShownObjects = useSetShownObjects();

  return (
    // @ts-ignore
    <Dialog
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      PaperProps={{ style: { backgroundColor: '#FFFFFFAA' } }}
      {...other}
    >
      <DialogTitle id="confirm-dialog-title"><Trans>ShowObjectsTitle</Trans></DialogTitle>
      <List>
        <Item title={t('Bridges')} name="most" shownObjects={shownObjects} setShownObjects={setShownObjects} />
        <Item title={t('Tributary')} name="pritok" shownObjects={shownObjects} setShownObjects={setShownObjects} />
        <Item title={t('Eating')} name="jidlo" shownObjects={shownObjects} setShownObjects={setShownObjects} />
        <Item title={t('Weirs')} name="jez-x" shownObjects={shownObjects} setShownObjects={setShownObjects} />
        <Item title={t('Gauge')} name="vodocet" shownObjects={shownObjects} setShownObjects={setShownObjects} />
        <Item title={t('Camp')} name="taboriste" shownObjects={shownObjects} setShownObjects={setShownObjects} />
        <Item
          title={t('BeginEnd')}
          name="zacatek_konec"
          shownObjects={shownObjects}
          setShownObjects={setShownObjects}
        />
        <Item title={t('Other')} name="_other" shownObjects={shownObjects} setShownObjects={setShownObjects} />
      </List>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ObjectFilterDialog;
