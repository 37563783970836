import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

function ConfirmDialog({
  title,
  description = undefined,
  positiveButton,
  negativeButton,
  onConfirm,
  classes = undefined,
  onClose = undefined,
  ...other
}) {
  function handleClose() {
    onClose();
  }

  function handleConfirm() {
    onClose();
    if (onConfirm) onConfirm();
  }

  return (
    // @ts-ignore
    <Dialog
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      {...other}
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-title">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {negativeButton}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {positiveButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
