import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: '5px',
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    // backgroundColor: theme.palette.background.paper,
  },
  bold: {
    fontWeight: 'bold',
  },
}));

export default function NameValueView({ name, value, link = undefined }) {
  const classes = useStyles();
  if (!value) return null;
  return (
    <div className={classes.wrapper}>
      <span className={classes.bold}>{name}:</span> {link ? <a href={link}>{value}</a> : value}
    </div>
  );
}
