import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import useT from '../utility/useT';
import { Trans } from 'react-i18next';
import nativeInterface from '../utility/nativeInterface';

function ChooseFromListDialog({ title = undefined, items = [], onClose = undefined, children = null, ...other }) {
  const t = useT();
  function handleClose() {
    onClose();
  }
  const native = nativeInterface();

  return (
    // @ts-ignore
    <Dialog onClose={handleClose} aria-labelledby="confirm-dialog-title" {...other}>
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <List>
        {items.map((item) => {
          const Icon = item.icon;
          const content = (
            <>
              {item.icon && (
                <ListItemAvatar>
                  <Avatar>
                    <Icon />
                  </Avatar>
                </ListItemAvatar>
              )}
              <ListItemText primary={item.text} />
            </>
          );

          return item.onClick || native ? (
            <ListItem
              button
              onClick={() => {
                if (item.onClick) item.onClick();
                else if (item.link && native && native.openUrl) native.openUrl(item.link);
                handleClose();
              }}
              key={item.key}
            >
              {content}
            </ListItem>
          ) : (
            <ListItem button component={Link} href={item.link} target={item.target} key={item.key}>
              {content}
            </ListItem>
          );
        })}
        {children}
      </List>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Trans>Close</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChooseFromListDialog;
