import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rkm: {
    display: 'flex',
    position: 'relative',
    top: '-25px',
    height: '20px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  river: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    top: '-20px',
    height: '0px',
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

export default function AvatarBar({ children, usage }) {
  const classes = useStyles();
  return <div className={classes[usage]}>{children}</div>;
}
