import React from 'react';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';

import HomeIcon from '@material-ui/icons/Home';
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import useT from '../utility/useT';

function RouteMenuItem({ title, to, icon: Icon }) {
  return (
    <MenuItem component={Link} to={to}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </MenuItem>
  );
}

function ActionMenuItem({ title, action, icon: Icon }) {
  return (
    <MenuItem component={Link} onClick={action}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </MenuItem>
  );
}

function MainMenu() {
  const { enqueueSnackbar } = useSnackbar();
  const t = useT();

  return (
    <div>
      <List>
        <RouteMenuItem to={'/'} icon={HomeIcon} title={t('HomePage')} />
        <RouteMenuItem to={'/offline'} icon={GetAppIcon} title={t('OfflineData')} />
        <RouteMenuItem to={'/settings'} icon={SettingsIcon} title={t('Settings')} />
        <RouteMenuItem to={'/explanations'} icon={HelpIcon} title={t('Explanations')} />
        <RouteMenuItem to={'/about'} icon={InfoIcon} title={t('About')} />

        {/* <Divider />

        <ActionMenuItem
          icon={SubdirectoryArrowRightIcon}
          title={"Stáhnout Vltavu"}
          action={() => {
            enqueueSnackbar(`Tady zatim nejde, pouzij tlacitko na homepage`, {
              variant: "warning"
            });
          }}
        /> */}
      </List>
    </div>
  );
}

export default MainMenu;
