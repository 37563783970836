import React from 'react';
import _ from 'lodash';
import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles } from '@material-ui/core/styles';
import useT from '../utility/useT';
import { drawerWidth } from '../utility/useViewportWidth';

const useStyles = makeStyles((theme) => ({
  header: {
    // marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    // backgroundColor: theme.palette.background.paper,
  },
  select: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(75vw - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '65vw',
    },
    maxWidth: '400px',
  },
}));

export default function KmSelector({ options, label, setSection, section, field, onChoose = undefined }) {
  const classes = useStyles();
  const t = useT();
  return (
    <div>
      <div>{label}:</div>
      <div>
        <NativeSelect
          value={section[field]}
          onChange={(e) => {
            if (onChoose && e.target.value == 'choose') onChoose();
            else
              setSection({
                ..._.pick(section, ['begin', 'end']),
                [field]: e.target.value,
              });
          }}
          className={classes.select}
        >
          {onChoose && <option value="choose">{t('ChooseInGuide')}</option>}

          {options.map((x) => (
            <option key={x.km} value={x.km}>
              {x.name} (km {x.km})
            </option>
          ))}
        </NativeSelect>
      </div>
    </div>
  );
}
