import React from 'react';
import AppLayout from '../layout/AppLayout';
import useReactRouter from 'use-react-router';
import { useRiverDetail } from '../datalayer/api';
import { extractRiverName, extractKnownSections, extractRiverGuide } from '../datalayer/extractors';
import { useSelectedSection, useShownObjects, useSetSelectedSection } from '../utility/globalState';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Radio from '@material-ui/core/Radio';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { roundKm } from '../utility/mathutil';
import useT from '../utility/useT';
import InfoBox from '../ui-elements/InfoBox';

function formatSectionText(section) {
  const { range, difficulty } = section;
  const { begin, end } = range;
  return `${roundKm(begin - end)} km, ${difficulty}, rkm ${roundKm(begin)}-${roundKm(end)}`;
}

export default function RiverDifficultyPage() {
  const { match, history } = useReactRouter();
  const { ID_reky } = match.params;
  const setSection = useSetSelectedSection(ID_reky);

  const riverDetail = useRiverDetail(ID_reky);
  const name = extractRiverName(riverDetail);
  const section = useSelectedSection(ID_reky);
  const knownSections = React.useMemo(() => extractKnownSections(riverDetail), [riverDetail]);
  const guide = extractRiverGuide(riverDetail);
  const isGuide = guide.length > 0;

  const subMenuItems = [];
  const t = useT();

  const handleClick = (section) => {
    if (!isGuide) return;
    const { range, name } = section;
    setSection({
      ...range,
      difficultySectionName: name,
    });
    history.goBack();
    // showGuide();
  };

  return (
    <AppLayout title={`${name} - ${isGuide ? t('chooseSection') : t('Sections').toLocaleLowerCase()}`} subMenuItems={subMenuItems}>
      <List>
        {knownSections.map((x) => (
          <ListItem button onClick={() => handleClick(x)}>
            {isGuide && (
              <ListItemIcon>
                <Radio
                  edge="start"
                  checked={!!(x.range.begin == section.begin && x.range.end == section.end)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
            )}
            <ListItemText primary={x.name} secondary={formatSectionText(x)} />
          </ListItem>
        ))}
      </List>
      {isGuide && <InfoBox title={t('CustomSectionInfoTitle')} message={t('CustomSectionInfoBody')} />}
    </AppLayout>
  );
}
