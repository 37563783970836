import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useDimensions from '../utility/useDimensions';

const useStyles = makeStyles((them) => ({
  root: {
    visibility: 'hidden',
    position: 'fixed',
  },
}));

export default function AppBarMeasure({ onChangeHeight }) {
  const classes = useStyles();
  const [containerRef, { height }] = useDimensions();
  React.useEffect(() => {
    if (height > 0) {
      onChangeHeight(height);
    }
  }, [height]);

  return (
    <div className={classes.root} ref={containerRef}>
      <AppBar position="static">
        <Toolbar></Toolbar>
      </AppBar>
    </div>
  );
}
