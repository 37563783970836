import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Helmet } from 'react-helmet';
import theme from './theme';
import AppRoutes from './AppRoutes';
import { SnackbarProvider, useSnackbar } from 'notistack';

import ConfirmDialog from './ui-elements/ConfirmDialog';
import useStateDialog from './utility/useStateDialog';
import {
  RiverSelectedSectionsProvider,
  ShownObjectsProvider,
  ClassicalGuideProvider,
  WatchPositionProvider,
  RiverFilterProvider,
  WarrantyClosedProvider,
  DetailMapTypeProvider,
} from './utility/globalState';
import useT from './utility/useT';
import SnackbarCloseButton from './ui-elements/SnackbarCloseButton';

function AppCore() {
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirmReload, confirmReloadDialogProps] = useStateDialog();
  const t = useT();

  const doReloadPage = () => window.location.reload();

  React.useEffect(() => {
    // @ts-ignore
    window.showInfoMessage = (message) => enqueueSnackbar(t(message), { variant: 'info' });
    // @ts-ignore
    window.showErrorMessage = (message) => enqueueSnackbar(t(message), { variant: 'error' });
    // @ts-ignore
    window.showReloadDialog = openConfirmReload;
  }, []);

  return (
    <>
      <AppRoutes />
      <ConfirmDialog
        title={t('NewVersionAvailableReload')}
        positiveButton={t('Reload')}
        negativeButton={t('Cancel')}
        onConfirm={doReloadPage}
        {...confirmReloadDialogProps}
      />
    </>
  );
}

function App() {
  const t = useT();

  return (
    <>
      <CssBaseline />
      <Helmet>
        <title>RaftCZ</title>
        {/* <script
          src="https://apis.google.com/js/platform.js"
          // @ts-ignore
          onload="initGoogle()"
        /> */}
      </Helmet>
      <SnackbarProvider maxSnack={3} action={(key) => <SnackbarCloseButton key={key} />}>
        <MuiThemeProvider theme={theme}>
          <RiverSelectedSectionsProvider>
            <ClassicalGuideProvider>
              <WatchPositionProvider>
                <ShownObjectsProvider>
                  <RiverFilterProvider>
                    <WarrantyClosedProvider>
                      <DetailMapTypeProvider>
                        <AppCore />
                      </DetailMapTypeProvider>
                    </WarrantyClosedProvider>
                  </RiverFilterProvider>
                </ShownObjectsProvider>
              </WatchPositionProvider>
            </ClassicalGuideProvider>
          </RiverSelectedSectionsProvider>
        </MuiThemeProvider>
      </SnackbarProvider>
    </>
  );
}

export default App;
