// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import StreetviewIcon from '@material-ui/icons/Streetview';
import YoutubeIcon from '@material-ui/icons/YouTube';
import PhotoIcon from '@material-ui/icons/Photo';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';

import {
  extractRkmKm,
  extractRkmDescription,
  extractRkmIconDescription,
  extractRkmIcons,
  extractPhotos,
  extractMapPoints,
  extractRkmLinks,
} from '../datalayer/extractors';

const useStyles = makeStyles((theme) => ({
  streetviewIcon: {
    fontSize: '10pt',
    color: 'gold',
    // marginLeft: '2px',
  },
  youtubeIcon: {
    fontSize: '10pt',
    color: 'red',
    // marginLeft: '2px',
  },
  smallIcon: {
    fontSize: '10pt',
    // marginLeft: '2px',
  },
  iconsWrapper: {
    marginLeft: '5px',
  },
}));

function OverviewIcons({ rkmDetail, className = undefined }) {
  const isStreetView = React.useMemo(() => !!extractRkmLinks(rkmDetail).find((x) => x.typ == 'streetview'), [
    rkmDetail,
  ]);
  const isYoutube = React.useMemo(() => !!extractRkmLinks(rkmDetail).find((x) => x.typ == 'youtube'), [rkmDetail]);
  const isPhoto = React.useMemo(() => !!extractPhotos(rkmDetail).find((x) => x.photo_file), [rkmDetail]);
  const isLocation = React.useMemo(() => extractMapPoints(rkmDetail).length > 0, [rkmDetail]);

  const classes = useStyles();
  return (
    <span className={className}>
      {isStreetView && <StreetviewIcon className={classes.streetviewIcon} />}
      {isPhoto && <PhotoIcon className={classes.smallIcon} />}
      {isLocation && <LocationOnIcon className={classes.smallIcon} />}
      {isYoutube && <YoutubeIcon className={classes.youtubeIcon} />}
    </span>
  );
}

// function itemTitle(rkmDetail) {
//   return <>{extractRkmKm(rkmDetail)} km</>;

//   // if (!location) return item.km;
//   // const { latitude, longitude } = location;
//   // if (!latitude || !longitude) return item.km;
//   // if (gps) {
//   //   return `${item.km} (vzd ${Math.round(distance(latitude, longitude, gps.latitude, gps.longitude) * 10) / 10} km)`;
//   // }
//   // return item.km;
// }

export default function RkmListItem({
  rkmDetail,
  onClick = undefined,
  useImageGuide = false,
  onContextMenu = undefined,
}) {
  const classes = useStyles();
  const description = extractRkmDescription(rkmDetail);
  const descriptionIcon = extractRkmIconDescription(rkmDetail);
  const icons = extractRkmIcons(rkmDetail);
  if (useImageGuide) {
    return (
      <ListItem button onClick={onClick} onContextMenu={onContextMenu}>
        <ListItemAvatar>
          <div>{extractRkmKm(rkmDetail)}</div>

          <div>
            <OverviewIcons rkmDetail={rkmDetail} />
          </div>
        </ListItemAvatar>

        <div dangerouslySetInnerHTML={{ __html: descriptionIcon }} />
      </ListItem>
    );
  }
  return (
    <ListItem button onClick={onClick} onContextMenu={onContextMenu}>
      <ListItemAvatar>
        {icons.map((icon) => (
          <>
            <img width={20} height={20} src={`${process.env.REACT_APP_ROUTE_BASE}/images/${icon}.gif`} alt={icon} />
            <br />
          </>
        ))}
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            {extractRkmKm(rkmDetail)}
            <OverviewIcons rkmDetail={rkmDetail} className={classes.iconsWrapper} />
          </>
        }
        secondary={description && description.replace(/<\/?[^>]+(>|$)/g, '')}
      />
    </ListItem>
  );
}
