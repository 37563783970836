export default `
<h2>Limity sjízdnosti</h2>
<p>
Limity sjízdnosti jsou definovány pro plastové kánoe se středně zkušenou
posádkou, která si dokáže i za nižších stavů najít proudnici s dostatkem
vody. Pro majitele kajaků, zkušené vodáky nebo pro vodáky, kterým nevadí
občasné přetahování, může být limit nižší. Naopak pro rafty, laminátky
nebo začínající vodáky je obvykle nutné vody více o minimálně 10 cm.
Zároveň pozor při zvýšeném průtoku nebo povodňových stavech, kdy
obtížnost se může zvýšit o několik stupňů a úsek již nemusí být vhodný
pro začínající vodáky nebo posádky bez adekvátního vybavení.
</p>

<h2>Obtížnosti</h2>

<table cellspacing="0" cellpadding="4" border="1">
    <tbody>
        <tr align="center">
            <th>
                Charakter toku</th>
            <th>

                Nároky na vodáka
                a jeho vybavení</th>
            <th>
                Příklad úseku</th>
        </tr>
        <tr>
            <th colspan='3'>Obtížnost: ZW</th>
         </tr> 
        <tr>
            <td>Klidný, mírně proudící
                tok bez překážek a peřejí.</td>
            <td>Základní znalost ovládání
                lodě, loď zajištěná proti potopení,
                děti a neplavci vesty.</td>
            <td>Berounka při průtoku
                10 m3/s v Plzni.</td>
        </tr>
        <tr>
            <th colspan='3'>Obtížnost: WW I</th>
         </tr> 
        <tr>
            <td>Lehký tok s občasnými
                peřejemi.</td>
            <td>Znalost ovládání lodě, loď
                zajištěná proti potopení,
                vhodné vesty a helmy, znalost
                sebezáchrany.</td>
            <td>Lužnice z Tábora
                do Dobronice při průtoku
                10 m3/s v Bechyni.</td>
        </tr>
        <tr>
            <th colspan='3'>Obtížnost: WW II</th>
         </tr> 
        <tr>
            <td>Řeka s peřejemi a vlnami,
                dobře čitelné a viditelné;
                úzké toky s rychlejším
                proudem a občasnými
                návaly proudu na břeh
                a s možností překážek
                dobře viditelných.</td>
            <td>Dobrá znalost ovládání lodi,
                umění rychle zastavit, trénovanost
                a zkušenosti vhodné;
                lodě zajištěné proti potopení,
                vhodné uzavřené nebo samovylévací
                lodě, vesta a helma,
                znalost sebezáchrany, doporučená
                znalost záchrany jiných.</td>
            <td>Sázava z Krhanic
                při průtoku 15 m3/s
                v Nespekách.</td>
        </tr>
        <tr>
            <th colspan='3'>Obtížnost: WW III</th>
         </tr> 
        <tr>
            <td>Těžký tok s nepravidelnými
                vlnami a občasným válcem,
                někdy zablokované, nečekané
                překážky; u úzkých
                toků nepřehledná místa
                s peřejemi v meandrech
                s náhlými překážkami.</td>
            <td>Velmi dobrá znalost ovládání
                lodě a taktiky jízdy na rychlé
                a divoké vodě, uzavřené lodě
                nebo rafty, znalost sebezáchrany
                a záchrany jiných, vesta,
                helma, neopren, záchranné
                vybavení, je možná jízda bez
                prohlížení celého úseku.</td>
            <td>Labe od přehrady
                po Kukačku při průtoku
                20 m3/s.</td>
        </tr>
        <tr>
            <th colspan='3'>Obtížnost: WW IV</th>
         </tr> 
        <tr>
            <td>Velmi těžký tok s velkými
                peřejemi, válce, silná rozhraní,
                návaly vody, zablokované
                s úzkými průjezdy a stupni.
            </td>
            <td>dtto. WW III, velmi dobré
                znalosti záchrany, psychická
                odolnost a trénovanost,
                rychlá rozhodnutí, úsek
                předem prohlédnout.</td>
            <td>Kamenice z Plavů
                do Návarova při průtoku
                10 m3/s.</td>
        </tr>
        <tr>
            <th colspan='3'>Obtížnost: WW V</th>
         </tr> 
        <tr>
            <td>Extrémně těžký tok, velmi
                zablokované a nepřehledné,
                neustálé peřeje s málo místy
                na zastavení, vysoké kaskády
                a stupně, rychlý proud.</td>
            <td>Velmi rychlá rozhodnutí pod
                psychickým tlakem, velká zkušenost,
                velmi dobře sehrané
                skupinky s praxí v záchraně
                a velmi dobrým vybavením.</td>
            <td>Jizera z Mýta při průtoku
                nad 40 m3/s v Jablonci.</td>
        </tr>
        <tr>
            <th colspan='3'>Obtížnost: WW VI</th>
         </tr> 
        <tr>
            <td>Hranice sjízdnosti, kdy tok
                může být sjízdný v kombinaci
                s vhodným stavem vody
                a dalšími podmínkami.</td>
            <td>dtto. WW V</td>
            <td>Vydra za velké vody.</td>
        </tr>

    </tbody>
</table>

<p>
Velmi významný vliv na obtížnost toku má jeho charakter, spád a aktuální
průtok. Je třeba si uvědomit, že obtížnost bývá stanovována při běžném
optimálním průtoku. Jeho zvýšení, například během povodně, může způsobit
nárůst obtížnosti nebo až nesjízdnost! Charakter toku je taktéž
důležitý, jelikož u nepřehledného úzkého potoka s obtížností WW II a
možností překážek (padlých stromů, nízkých lávek), je sjezd technicky
náročnější než na přehledné, široké a peřejnaté řece s obtížností taktéž
WW II.
</p>

<p>
Zvolený typ řeky, a tím pádem i obtížnost, se promítá do potřebného
vybavení. Například vesta s helmou by se měla používat již od obtížnosti
WW I. Helma by se měla používat i na mírně tekoucích řekách s malým
množstvím vody a zvláště u uzavřených lodí. V případě překlopení asi
nebude hrozit utonutí, když je vody v řece po kolena, ale spíše hrozí
náraz hlavou o kámen. Proto je helma důležitá i na jednoduché vodě jako
prevence před úrazem. Pozor také na odlišnosti zahraničních řek.
</p>

`;
