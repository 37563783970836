import React from 'react';
import useReactRouter from 'use-react-router';
import AppLayout from '../layout/AppLayout';
import useT from '../utility/useT';
import LoadingInfo from '../ui-elements/LoadingInfo';
import ErrorInfo, { extractErrorMessage } from '../ui-elements/ErrorInfo';
import { useRiverGauges } from '../datalayer/api';
import { extractRiverNameFromGauge, extractSectionsFromGauge } from '../datalayer/extractors';
import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../utility/useViewportWidth';
import { Semaphore } from './GaugeListItem';

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: '100vw',
  },
  table: {
    borderCollapse: 'collapse',
    // border: '1px solid gray',
    margin: '5px',
    width: 'calc(100vw - 25px)',
    maxWidth: '590px',

    [theme.breakpoints.up('sm')]: {
      width: `calc(100vw - 25px - ${drawerWidth}px)`,
    },
  },
  th: {
    border: '1px solid #bbb',
    padding: '3px',
    backgroundColor: '#eee',
  },
  td: {
    border: '1px solid #bbb',
    padding: '3px',
  },
  tdRight: {
    border: '1px solid #bbb',
    padding: '3px',
    textAlign: 'right',
  },
  tdCenter: {
    border: '1px solid #bbb',
    padding: '3px',
    textAlign: 'center',
  },
  heading: {
    fontSize: '20px',
    margin: '10px',
    fontWeight: 'bold',
  },
}));

function getGrafUrl(section) {
  if (!section || !section.graf) return null;
  return section.graf.obr.replace('_m.gif', '.png');
}

export default function SectionDetailPage() {
  const { match, history } = useReactRouter();
  const { ID_reky, index } = match.params;
  const riverGauges = useRiverGauges(ID_reky);
  const riverName = extractRiverNameFromGauge(riverGauges);
  const sections = extractSectionsFromGauge(riverGauges);
  const section = sections[index] || {};
  const t = useT();
  const grafUrl = getGrafUrl(section);
  const classes = useStyles();
  const { Stav } = section;
  const { limit } = section;
  const { vodocet } = section;
  const jednotka = limit ? limit['@jednotka'] : '';

  return (
    <AppLayout title={`${section.od} - ${riverName}`}>
      {riverGauges && riverGauges.state == 'pending' ? (
        <LoadingInfo message={t('LoadingGauges')} />
      ) : riverGauges && riverGauges.state == 'rejected' ? (
        <ErrorInfo message={riverGauges.error && extractErrorMessage(riverGauges)} />
      ) : (
        <div>
          {limit && (
            <table className={classes.table}>
              <tbody>
                <tr>
                  <th className={classes.th} colSpan={3}>
                    {t('Gauge')}
                  </th>
                </tr>
                <tr>
                  <td className={classes.td} colSpan={3}>
                    {vodocet}
                  </td>
                </tr>
                <tr>
                  <th className={classes.th} colSpan={3}>
                    {t('WaterLevelLimits')}
                  </th>
                </tr>
                <tr>
                  <th className={classes.th}>{t('Minimum')}</th>
                  <th className={classes.th}>{t('Optimum')}</th>
                  <th className={classes.th}>{t('Maximum')}</th>
                </tr>
                <tr>
                  <td className={classes.tdCenter}>{limit.minimum ? `${limit.minimum} ${jednotka}` : ''}</td>
                  <td className={classes.tdCenter}>{limit.optimum ? `${limit.optimum} ${jednotka}` : ''}</td>
                  <td className={classes.tdCenter}>{limit.maximum ? `${limit.maximum} ${jednotka}` : ''}</td>
                </tr>
                <tr>
                  <th className={classes.th} colSpan={3}>
                    {t('CurrentValue')}
                  </th>
                </tr>
                <tr>
                  <th className={classes.th}>{t('Passability')}</th>
                  <th className={classes.th}>{t('Flow')}</th>
                  <th className={classes.th}>{t('Height')}</th>
                </tr>
                <tr>
                  <td className={classes.tdCenter}>
                    <Semaphore gauge={section} />
                  </td>
                  <td className={classes.tdCenter}>{section.prutok ? `${section.prutok} m3/s` : ''}</td>
                  <td className={classes.tdCenter}>{section.vyska ? `${section.vyska} cm` : ''}</td>
                </tr>
              </tbody>
            </table>
          )}

          {grafUrl && <img className={classes.image} src={grafUrl} />}

          <table className={classes.table}>
            <tbody>
              <tr>
                <th className={classes.th}>{t('Date')}</th>
                <th className={classes.th}>{t('Flow')}</th>
                <th className={classes.th}>{t('Height')}</th>
              </tr>
              {(Stav || []).map((row) => (
                <tr key={row.Datum}>
                  <td className={classes.td}>{row.Datum}</td>
                  <td className={classes.tdRight}>{row.Prutok}</td>
                  <td className={classes.tdRight}>{row.Vyska}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </AppLayout>
  );
}
