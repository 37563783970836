import React from 'react';

export default function Flag({ size }) {
  return (
    <svg viewBox="0 0 9 6" width={size} height={size}>
      <rect fill="#fff" width="9" height="3" />
      <rect fill="#d52b1e" y="3" width="9" height="3" />
      <rect fill="#0039a6" y="2" width="9" height="2" />
    </svg>
  );
}
