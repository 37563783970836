import React from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Map from 'pigeon-maps';
import Marker from '../maps/Marker';
import useViewportWidth from '../utility/useViewportWidth';
import { mapProviders, mapAttribution } from '../maps/PointsMap';
import { extractMapPoints } from '../datalayer/extractors';
import useStateDialog from '../utility/useStateDialog';
import GpsDetailDialog from './GpsDetailDialog';
import useOnlineStatus from '../utility/useOnlineStatus';
import { useDetailMapType } from '../utility/globalState';
import { distance } from '../utility/mathutil';

const useStyles = makeStyles((theme) => ({
  headerImage: {
    backgroundImage: `url("${process.env.REACT_APP_ROUTE_BASE}/images/feature.jpg")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100vw',
    height: '160px',
  },
}));

function getDefaultZoom(points, diam) {
  if (points.length == 1) return 18;
  if (points.length == 0) return 15;
  if (diam > 1) return 13;
  if (diam > 0.3) return 14;
  if (diam > 0.1) return 15;
  return 16;
}

export default function RkmDetailMap({ rkmDetail }) {
  const { gps } = rkmDetail || {};
  const classes = useStyles();

  const [selectedGps, setSelectedGps] = React.useState(null);
  const [openDialog, dialogProps] = useStateDialog();
  const online = useOnlineStatus();
  const mapType = useDetailMapType();

  const width = useViewportWidth();

  const points = React.useMemo(() => extractMapPoints(rkmDetail), [rkmDetail]);
  const [center, zoom] = React.useMemo(() => {
    if (!points || points.length == 0) return [gps, 15];
    const minlat = _.minBy(points, 'gps.latitude').gps.latitude;
    const minlon = _.minBy(points, 'gps.longitude').gps.longitude;
    const maxlat = _.maxBy(points, 'gps.latitude').gps.latitude;
    const maxlon = _.maxBy(points, 'gps.longitude').gps.longitude;
    const diam = distance(minlat, minlon, maxlat, maxlon);
    return [
      {
        latitude: (minlat + maxlat) / 2,
        longitude: (minlon + maxlon) / 2,
      },
      getDefaultZoom(points, diam),
    ];
  }, [points, gps]);

  if (!center || !online) return <div className={classes.headerImage} />;
  if (!width) return null;

  return (
    <>
      <Map
        defaultCenter={[center.latitude, center.longitude]}
        defaultZoom={zoom}
        defaultWidth={width}
        // must be set defaultWidth instead of width, otherwise mouse wheel zoom will not work
        // width={width}

        height={160}
        provider={mapProviders[mapType]}
        attribution={mapAttribution.mapycz}
      >
        {points.map((point, index) => (
          <Marker
            anchor={[point.gps.latitude, point.gps.longitude]}
            key={index}
            onClick={() => {
              setSelectedGps(point.gps);
              // @ts-ignore
              openDialog();
            }}
            image={point.icon}
          />
        ))}
      </Map>
      <GpsDetailDialog coordinates={selectedGps} dialogProps={dialogProps} />
    </>
  );
}
