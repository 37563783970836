import _ from 'lodash';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppLayout from '../layout/AppLayout';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import useReactRouter from 'use-react-router';
import { useRiverDetail } from '../datalayer/api';
import {
  extractRiverComments,
  extractRiverLogs,
  extractRiverArticles,
  extractRiverName,
  extractArticleLink,
  extractArticleListLink,
  extractCommentListLink,
  extractLogListLink,
  extractLogCount,
  extractCommentCount,
  extractArticleCount,
} from '../datalayer/extractors';
import useT from '../utility/useT';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '1rem',
  },
  text: {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  gauge: {
    fontSize: '0.875rem',
    color: 'blue',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
    fontSize: '1rem',
  },
  length: {
    fontWeight: 'bold',
  },
  number: {
    fontWeight: 'bold',
  },
}));

function ArticleListItem({ item }) {
  return <ListItemText primary={`${item.Nazev} - ${item.Datum}`} secondary={item.Popis} />;
}

function CommentListItem({ item }) {
  const [showFull, setShowFull] = React.useState(false);
  return (
    <ListItemText
      onClick={() => setShowFull((v) => !v)}
      primary={`${item.Jmeno || item.Nazev} - ${item.Datum}`}
      secondary={showFull ? item.Popis : _.truncate(item.Popis, { length: 150, separator: ' ' })}
    />
  );
}

function LogListItem({ item }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.title}>
        {item.Autor} - {item.Datum}
      </div>
      <div className={classes.gauge}>{item.Vodocet}</div>
      <div>
        {item.Trasa} {item.Delka && <span className={classes.length}>({item.Delka} km)</span>}
      </div>
      <div className={classes.text}>{item.Popis}</div>
    </div>
  );
}

function RiverAdditionalInfoPage({
  extractor,
  title,
  ItemComponent,
  extractItemLink = undefined,
  extractAllLink,
  extractCount,
  nextDataTitle,
}) {
  const { history, match } = useReactRouter();
  const { ID_reky } = match.params;
  const riverDetail = useRiverDetail(ID_reky);
  const items = extractor(riverDetail);
  const classes = useStyles();
  const t = useT();

  return (
    <AppLayout title={`${title} - ${extractRiverName(riverDetail)}`}>
      <List>
        {items.map((item, index) => {
          const link = extractItemLink ? extractItemLink(riverDetail, item) : null;
          return (
            <ListItem key={index} button={link ? true : undefined} onClick={link ? () => window.open(link) : null}>
              <ItemComponent item={item} />
            </ListItem>
          );
        })}
        <ListItem button onClick={() => window.open(extractAllLink(riverDetail))}>
          <ListItemIcon>
            <img src={`${process.env.REACT_APP_ROUTE_BASE}/logo192.png`} alt="limit" width={40} height={40} />
          </ListItemIcon>
          <div>
            <div className={classes.link}>www.raft.cz</div>
            <div className={classes.text}>
              {nextDataTitle} (<span className={classes.number}>{extractCount(riverDetail)}</span>)
            </div>
          </div>
        </ListItem>
      </List>
    </AppLayout>
  );
}

export function RiverCommentsPage() {
  const t = useT();
  return (
    <RiverAdditionalInfoPage
      extractor={extractRiverComments}
      title={t('CommentsShort')}
      ItemComponent={CommentListItem}
      extractAllLink={extractCommentListLink}
      nextDataTitle={t('NextComments')}
      extractCount={extractCommentCount}
    />
  );
}

export function RiverLogsPage() {
  const t = useT();
  return (
    <RiverAdditionalInfoPage
      extractor={extractRiverLogs}
      title={t('RiverLogs')}
      ItemComponent={LogListItem}
      extractAllLink={extractLogListLink}
      nextDataTitle={t('NextLogs')}
      extractCount={extractLogCount}
    />
  );
}

export function RiverArticlesPage() {
  const t = useT();
  return (
    <RiverAdditionalInfoPage
      extractor={extractRiverArticles}
      title={t('Articles')}
      ItemComponent={ArticleListItem}
      extractItemLink={extractArticleLink}
      extractAllLink={extractArticleListLink}
      nextDataTitle={t('NextArticles')}
      extractCount={extractArticleCount}
    />
  );
}
