import _ from 'lodash';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import useReactRouter from 'use-react-router';
import RkmDetailPage from './RkmDetailPage';

function RkmDetailDialog({ onClose = undefined, riverDetail, rkmDetail = undefined, open = false, ...other }) {
  const { history } = useReactRouter();

  function handleClose() {
    onClose();
  }
  React.useEffect(() => {
    if (open && !rkmDetail) history.goBack();
  }, []);

  return (
    // @ts-ignore
    <Dialog
      onClose={handleClose}
      open={open}
      aria-labelledby="place-details-dialog-title"
      fullScreen
      {...other}
      // {...(photos.length > 0 ? other : {})}
    >
      {open && rkmDetail && riverDetail && <RkmDetailPage riverDetail={riverDetail} rkmDetail={rkmDetail} />}
    </Dialog>
  );
}

export default RkmDetailDialog;
