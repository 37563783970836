import _ from 'lodash';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.background.paper,
  },
}));

export default function Panel({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Paper>
        <div className={classes.wrapper}>{children}</div>
      </Paper>
    </div>
  );
}
