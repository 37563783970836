import _ from 'lodash';
import React from 'react';
import useStorage from './useStorage';

function createGlobalState(defaultValue) {
  const Context = React.createContext(null);

  function Provider({ children }) {
    const [currentvalue, setCurrentValue] = React.useState(defaultValue);
    return <Context.Provider value={[currentvalue, setCurrentValue]}>{children}</Context.Provider>;
  }

  function useValue() {
    return React.useContext(Context)[0];
  }

  function useSetValue() {
    return React.useContext(Context)[1];
  }

  return [Provider, useValue, useSetValue];
}

function createStorageState(storageKey, defaultValue) {
  const Context = React.createContext(null);

  function Provider({ children }) {
    const [currentvalue, setCurrentValue] = useStorage(storageKey, localStorage, defaultValue);
    return <Context.Provider value={[currentvalue, setCurrentValue]}>{children}</Context.Provider>;
  }

  function useValue() {
    return React.useContext(Context)[0];
  }

  function useSetValue() {
    return React.useContext(Context)[1];
  }

  return [Provider, useValue, useSetValue];
}

const [RiverSelectedSectionsProvider, useRiverSelectedSections, useSetRiverSelectedSections] = createStorageState(
  'riverSelectedSections',
  {}
);
export { RiverSelectedSectionsProvider, useRiverSelectedSections, useSetRiverSelectedSections };

const [ShownObjectsProvider, useShownObjects, useSetShownObjects] = createGlobalState({
  most: true,
  pritok: true,
  jidlo: true,
  'jez-x': true,
  vodocet: true,
  taboriste: true,
  zacatek_konec: true,
  _other: true,
});

export { ShownObjectsProvider, useShownObjects, useSetShownObjects };

export function useSelectedSection(riverId) {
  const selectedSections = useRiverSelectedSections();
  const res = selectedSections[riverId];
  return res || { begin: 0, end: 0 };
}

export function useSetSelectedSection(riverId) {
  const selectedSections = useRiverSelectedSections();
  const setSelectedSections = useSetRiverSelectedSections();

  return (section) => {
    setSelectedSections({
      ...selectedSections,
      [riverId]: section,
    });
  };
}

const [ClassicalGuideProvider, useClassicalGuide, useSetClassicalGuide] = createStorageState('classicalGuide', 'false');
export { ClassicalGuideProvider, useClassicalGuide, useSetClassicalGuide };

const [WatchPositionProvider, useWatchPosition, useSetWatchPosition] = createStorageState('watchPosition', 'false');
export { WatchPositionProvider, useWatchPosition, useSetWatchPosition };

const [RiverFilterProvider, useRiverFilter, useSetRiverFilter] = createStorageState('riverFilter', {
  onlyRunnable: 'false',

  showRivers: 'true',
  showCreeks: 'true',
  showChannels: 'true',

  showBeginner: 'true',
  showAdvanced: 'true',
  showExpert: 'true',
});
export { RiverFilterProvider, useRiverFilter, useSetRiverFilter };

const [WarrantyClosedProvider, useWarrantyClosed, useSetWarrantyClosed] = createStorageState('warrantyClosed', 'false');
export { WarrantyClosedProvider, useWarrantyClosed, useSetWarrantyClosed };

const [DetailMapTypeProvider, useDetailMapType, useSetDetailMapType] = createStorageState('detailMapType', 'maps_google_photo');
export { DetailMapTypeProvider, useDetailMapType, useSetDetailMapType };
