import React from 'react';
import _ from 'lodash';
import useReactRouter from 'use-react-router';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import RiverIcon from '../icons/River';
import CreekIcon from '../icons/Creek';
import ChannelIcon from '../icons/Channel';

import { makeStyles } from '@material-ui/core/styles';
import Flag from '../icons/Flag';
import useT from '../utility/useT';

const useStyles = makeStyles((theme) => ({
  notFoundWrapper: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: '1rem',
  },

  info: {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  grow: {
    flexGrow: 1,
  },
}));

function getRiverSubtitle(river, t) {
  const difTitles = {
    Z: t('Beginner'),
    S: t('Advanced'),
    E: t('Expert'),
  };
  const { H_obtiznost, S_obtiznost, D_obtiznost } = river;
  const diffs = [H_obtiznost, S_obtiznost, D_obtiznost];
  return _.uniq(diffs)
    .map((x) => difTitles[x].toLowerCase())
    .join(', ');
}

function countryUrlFromRiver(river, countries) {
  const { ID_zeme } = river;
  const country = countries.find((x) => x.ID_zeme == ID_zeme);
  if (country) return country.Url;
  return null;
}

function RiverListItemIcon({ river }) {
  if (river.Reka == 0) return <CreekIcon />
  // if (river.Reka == 2) return <DirectionsBoatIcon />
  if (river.Reka == 2) return <ChannelIcon />
  return <RiverIcon />
}

function RiverListItem({ river, countries, riverSemaphores }) {
  const classes = useStyles();
  const t = useT();
  const { history } = useReactRouter();

  return (
    <ListItem key={river.ID_reky} button onClick={() => history.push(`/river/${river.ID_reky}`)}>
      <ListItemIcon><RiverListItemIcon river={river} /></ListItemIcon>

      {/* <div className={classes.grow}>
        <div className={classes.title}>{river.Nazev}</div>
        <div className={classes.info}>{getRiverSubtitle(river, t)}</div>
      </div> */}

      <ListItemText primary={river.Nazev} secondary={getRiverSubtitle(river, t)} />
      {countries && <Flag url={countryUrlFromRiver(river, countries)} />}
      {riverSemaphores && river.ID_reky in riverSemaphores && (
        <img
          src={`${process.env.REACT_APP_ROUTE_BASE}/images/${riverSemaphores[river.ID_reky]}_limit.gif`}
          alt="limit"
        />
      )}
    </ListItem>
  );
}

export default function RiverList({ rivers, countries = undefined, riverSemaphores = undefined }) {
  const { history } = useReactRouter();
  const classes = useStyles();
  const t = useT();

  return rivers.length > 0 ? (
    <List>
      {rivers.map((river) => (
        <RiverListItem river={river} countries={countries} riverSemaphores={riverSemaphores} />
      ))}
    </List>
  ) : (
    <div className={classes.notFoundWrapper}>{t('RiversNotFound')}</div>
  );
}
