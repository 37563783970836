import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import useT, { getLocalizedMessage } from '../utility/useT';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
    // backgroundColor: theme.palette.background.paper,
  },
}));

export function extractErrorMessage(error) {
  if (error && error.message) return error.message;
  if (error) return error.toString();
  return null;
}

export default function ErrorInfo({ message }) {
  const classes = useStyles();
  const t = useT();
  return (
    <div className={classes.wrapper}>
      <Alert severity="error">
        <AlertTitle>
          <Trans>Error</Trans>
        </AlertTitle>
        {getLocalizedMessage(message, t)}
      </Alert>
    </div>
  );
}
