import React from 'react';
import { useSnackbar } from 'notistack';
import useT from './useT';
import { useWatchPosition } from './globalState';
import nativeInterface from './nativeInterface';

export function handleGeolocationError(err, enqueueSnackbar, t) {
  if (err.code === err.PERMISSION_DENIED) {
    enqueueSnackbar(t('GeolocationNotPermitted'), {
      variant: 'warning',
    });
  } else {
    console.error(err);
    enqueueSnackbar(`${t('ErrorGettingLocation')}: ${err && err.message}`, {
      variant: 'error',
    });
  }
}

let lastLocationDate = null;
window['receiveNativePosition'] = (latitude, longitude) => {
  const date = new Date();
  if (lastLocationDate == null || date.getTime() - lastLocationDate.getTime() > 1000 * 30) {
    lastLocationDate = date;
    const setLocation = window['func_setLocation'];
    if (setLocation) {
      setLocation({ latitude, longitude });
    }
  }
};

export default function useLocation(locationRequired = true) {
  const [location, setLocation] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const watchPosition = useWatchPosition();

  const t = useT();

  React.useEffect(() => {
    if (locationRequired && watchPosition == 'true') {
      if (nativeInterface()) {
        window['func_enqueueSnackbar'] = enqueueSnackbar;
        window['func_setLocation'] = setLocation;
        lastLocationDate = null;
        nativeInterface().startWatchPosition();

        setLocation({ state: 'pending' });
        return () => {
          window['func_enqueueSnackbar'] = null;
          window['func_setLocation'] = null;
          lastLocationDate = null;
          nativeInterface().stopWatchPosition();
        };
      } else if (navigator.geolocation) {
        var locationOptions = {
          enableHighAccuracy: true,
          timeout: 30000,
          maximumAge: 30000,
        };

        const watchId = navigator.geolocation.watchPosition(receivePosition, onError, locationOptions);

        setLocation({ state: 'pending' });
        return () => {
          navigator.geolocation.clearWatch(watchId);
        };
      } else {
        setLocation(null);
      }
    } else {
      setLocation(null);
    }
  }, [locationRequired, watchPosition]);

  const receivePosition = (position) => {
    const { latitude, longitude } = position.coords;

    setLocation({ latitude, longitude });
  };

  const onError = (err) => {
    handleGeolocationError(err, enqueueSnackbar, t);
    setLocation({});
  };

  return location;
}
