import React from 'react';

export default function Flag({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 5 3">
      <rect width="5" height="3" fill="#fff" />
      <rect width="5" height="2" y="1" fill="#00966E" />
      <rect width="5" height="1" y="2" fill="#D62612" />
    </svg>
  );
}
