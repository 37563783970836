import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// @ts-ignore
import en from './translations/en.yaml';
// @ts-ignore
import cs from './translations/cs.yaml';
// @ts-ignore
import sk from './translations/sk.yaml';
// @ts-ignore
import de from './translations/de.yaml';
// @ts-ignore
import pl from './translations/pl.yaml';

i18n
  // load translation using xhr -> see /public/locales
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'cs',
    // debug: true,
    defaultNS: 'common',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      en: { common: en },
      cs: { common: cs },
      de: { common: de },
      sk: { common: sk },
      pl: { common: pl },
    },
    react: {
      useSuspense: false,
    },

    // saveMissing: true,
    // missingKeyHandler: (lng, ns, key, fallbackValue) => {
    //   const missingKeys = window.missingTranslationKeys || [];
    //   if (!missingKeys.includes(key)) missingKeys.push(key);
    //   window.missingTranslationKeys = missingKeys;
    //   window.missingTranslationKeysFormatted = missingKeys.map(key => `${key}: ${key}`).join('\n');
    // },
  });

export default i18n;
