import React from 'react';
import AppLayout from '../layout/AppLayout';
import useReactRouter from 'use-react-router';
import { useRiverDetail } from '../datalayer/api';
import { extractRiverName } from '../datalayer/extractors';
import useStateDialog from '../utility/useStateDialog';
import ObjectFilterDialog from './ObjectFilterDialog';
import { useSelectedSection, useShownObjects, useWatchPosition, useSetWatchPosition } from '../utility/globalState';
import RiverMap from './RiverMap';
import useT from '../utility/useT';

export default function RiverMapPage() {
  const { match } = useReactRouter();
  const { ID_reky } = match.params;
  const t = useT();
  const watchPosition = useWatchPosition();
  const setWatchPosition = useSetWatchPosition();

  const riverDetail = useRiverDetail(ID_reky);
  const name = extractRiverName(riverDetail);
  const [openFilter, filterDialogProps] = useStateDialog();
  const shownObjects = useShownObjects();
  const section = useSelectedSection(ID_reky);

  const subMenuItems = [];

  subMenuItems.push({
    label: t('FilterObjects'),
    handler: openFilter,
  });
  subMenuItems.push({
    label: t('WatchPosition'),
    checkboxValue: watchPosition,
    checkboxSetter: setWatchPosition,
  });

  return (
    <AppLayout title={name} subMenuItems={subMenuItems}>
      <RiverMap riverDetail={riverDetail} section={section} shownObjects={shownObjects} />
      <ObjectFilterDialog {...filterDialogProps} />
    </AppLayout>
  );
}
