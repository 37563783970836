import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { extractGaugeRange } from '../datalayer/extractors';
import useT from '../utility/useT';
import useOnlineStatus from '../utility/useOnlineStatus';
import { rangeIntersectionLength } from '../utility/mathutil';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '1rem',
  },

  info: {
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  infoBlack: {
    fontSize: '0.875rem',
  },
  grow: {
    flexGrow: 1,
  },
  gaugeAvatar: {
    width: 'calc((100vw - 60px) / 5)',
    maxWidth: '100px',
    borderRight: '1px solid #aaa',
    padding: '2px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  gaugeName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '7pt',
    marginTop: '5px',
    textAlign: 'center',
  },
  avatarWrap: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  gaugeSelected: {
    fontWeight: 'bold',
    color: 'blue',
  },
  fallback: {
    fontSize: '16pt',
  },
}));

function waterLevelImage(gauge) {
  switch (gauge.sjizdnost) {
    case '1':
      return '1_limit.gif';
    case '2':
      return '2_limit.gif';
    case '3':
    case 'B':
    case 'P':
    case 'O':
      return '3_limit.gif';
    default:
      return null;
  }
}

export function Semaphore({ gauge, size = undefined, drawFallback = undefined, className = undefined }) {
  const classes = useStyles();
  if (gauge == null) return null;
  const image = waterLevelImage(gauge);
  if (!image) {
    if (drawFallback) return <span className={classes.fallback}>?</span>;
    return null;
  }

  return (
    <img
      src={`${process.env.REACT_APP_ROUTE_BASE}/images/${image}`}
      alt="limit"
      width={size}
      height={size}
      className={className}
    />
  );
}

export default function GaugeListItem({
  gauge,
  section,
  handleClick,
  isGuide = false,
  showRadio = false,
  showChart = false,
  difficultySections = null,
}) {
  const classes = useStyles();
  const t = useT();
  const online = useOnlineStatus();

  const gaugeRange = extractGaugeRange(gauge);

  const difficulties =
    difficultySections
      ?.filter((x) => rangeIntersectionLength(x, gaugeRange) >= 0.5)
      ?.map((x) => x.difficulty)
      ?.join(', ') || [];

  return (
    <ListItem button onClick={() => handleClick(gauge)}>
      {showRadio && isGuide && gauge.range.begin != null && gauge.range.end != null && (
        <ListItemIcon>
          <Radio
            edge="start"
            checked={!!(gauge.range.begin == section.begin && gauge.range.end == section.end)}
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
      )}
      <div className={classes.grow}>
        <div className={classes.title}>
          {gauge.od} {difficulties ? `(${difficulties})` : ''}
        </div>
        <div className={classes.infoBlack}>
          {`${gauge.prutok} m3/s, ${gauge.vyska} cm`}{' '}
          {gauge.limit && (
            <>
              ({t('gaugeLimit')} {gauge.limit['#text']} {gauge.limit['@jednotka']}; {t('gauge')} {gauge?.vodocet})
            </>
          )}
        </div>
        <div className={classes.info}>{`${gauge.datum} ${gauge.cas}:00`}</div>
        {showChart && gauge.graf && <img src={gauge.graf.obr} alt="gauge" />}
      </div>
      <Semaphore gauge={gauge} />
    </ListItem>
  );
}

export function GaugeAvatar({ gauge, selectedSection }) {
  const classes = useStyles();
  const { begin, end } = extractGaugeRange(gauge);
  const selected = selectedSection && selectedSection.begin == begin && selectedSection.end == end;
  return (
    <div className={classes.gaugeAvatar}>
      <div className={classes.avatarWrap}>
        <Semaphore gauge={gauge} drawFallback />
      </div>
      <div className={clsx(classes.gaugeName, selected ? classes.gaugeSelected : null)}>{gauge.od}</div>
    </div>
  );
}
