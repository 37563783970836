import React from 'react';

export default function Flag({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 1200 800">
      <rect width="1200" height="800" fill="#005BBB" />
      <rect width="1200" height="400" y="400" fill="#FFD500" />
    </svg>
  );
}
