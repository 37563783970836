import React from 'react';
import AppLayout from '../layout/AppLayout';
import useReactRouter from 'use-react-router';
import { useRiverDetail } from '../datalayer/api';
import RiverGuide from './RiverGuide';
import { extractRiverName } from '../datalayer/extractors';
import useStateDialog from '../utility/useStateDialog';
import ObjectFilterDialog from './ObjectFilterDialog';
import { useSelectedSection, useShownObjects, useClassicalGuide, useSetClassicalGuide, useWatchPosition, useSetWatchPosition } from '../utility/globalState';
import RiverDetailWrapper from './RiverDetailWrapper';
import useT from '../utility/useT';

export default function RiverGuidePage() {
  const { match } = useReactRouter();
  const { ID_reky } = match.params;
  const t = useT();

  const riverDetail = useRiverDetail(ID_reky);
  const name = extractRiverName(riverDetail);
  const classicalGuide = useClassicalGuide();
  const setClassicalGuide = useSetClassicalGuide();
  const watchPosition = useWatchPosition();
  const setWatchPosition = useSetWatchPosition();

  const [openFilter, filterDialogProps] = useStateDialog();
  const shownObjects = useShownObjects();
  const section = useSelectedSection(ID_reky);

  const subMenuItems = [];

  subMenuItems.push({
    label: t('ShowClassicalGuide'),
    checkboxValue: classicalGuide,
    checkboxSetter: setClassicalGuide,
  });
  subMenuItems.push({
    label: t('WatchPosition'),
    checkboxValue: watchPosition,
    checkboxSetter: setWatchPosition,
  });
  subMenuItems.push({
    label: t('FilterObjects'),
    handler: openFilter,
  });

  return (
    <AppLayout title={name} subMenuItems={subMenuItems}>
      <RiverDetailWrapper riverDetail={riverDetail}>
        <RiverGuide
          riverDetail={riverDetail}
          useImageGuide={classicalGuide == 'true'}
          section={section}
          shownObjects={shownObjects}
        />
      </RiverDetailWrapper>
      <ObjectFilterDialog {...filterDialogProps} />
    </AppLayout>
  );
}
