import useWindowSize from './useWindowSize';

export const drawerWidth = 240;

export default function useViewportWidth() {
  let { width } = useWindowSize() || {};
  if (width >= 600) {
    width -= drawerWidth;
    width -= 20;
  }
  return width;
}
