// @ts-nocheck
import { createMuiTheme } from '@material-ui/core/styles';

// const origTheme = createMuiTheme();

// console.log('origTheme', origTheme.palette);

const theme = createMuiTheme({
  tileBackgroundColor: '#CCCCFF',
  tileBackgroundOpacity: 0.7,
  palette: {
    type: 'light',
  },
});

export default theme;
