import _ from 'lodash';

const chars = ' aáäaaâbcćčdďeéěëeefghhiíiiîjklĺľmnńňnoóôooöőpqrŕřsśštťuúuůüuuvwxyýzźž0123456789'.toUpperCase();

export default function localeSort(arr, fieldName) {
  const map = {};

  for (let i = 0; i < arr.length; i++) {
    const index = [];
    let item = _.get(arr, `${i}.${fieldName}`, '');

    item.replace(/\s+/g, ' ');

    for (let j = 0; j < item.length; j++) {
      let pos = null;
      const ch = item.substr(j, 1).toUpperCase();

      if (j + 1 < item.length && ch === 'C' && item.substr(j + 1, 1).toUpperCase() === 'H') {
        pos = 22;
        j++;
      } else {
        pos = chars.indexOf(ch);
      }

      index.push(pos < 10 ? '0' + pos : pos);
    }

    map[item] = index.join('');
  }

  return [...arr].sort((a, b) => {
    const a2 = map[_.get(a, fieldName, '')];
    const b2 = map[_.get(b, fieldName, '')];

    if (a2 > b2) return 1;
    if (a2 < b2) return -1;

    return 0;
  });
}
