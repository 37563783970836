export default `
<h2>Water limits</h2>
<p>
The limits are defined almost for canoe. For kayaks can limit a few cm less, for rafts higher.
</p>

<h2>Difficulty of rivers</h2>

<p>
Difficulty of Czech and Slovak rivers is group to a couple of categories. In this country, we are using Alpen categories with 9 groups. Calm water has from ZWA (lake, dam), ZWB (a little running) to ZWC (soft running). You can find only one category ZW what is junction of all categories with calm water.
</p>

<p>
Whitewater has 6 groups: WW I (easy), WW II (a little hard), WW III (hard), WW IV (very hard), WW V (extreme hard), WW VI (very extreme hard). there is used for better adjustment sign + (high difficult) and - (less difficult).
</p>

<p>
Czech rivers are dependent on the water level. In the spring, we have a high water from snow, but we are dependent only on a rain during next mounths. You can mostly ship a upper part of the rivers only in spring or after heavy rain. It is recommended to concult with actual water level. Czech rivers have usually a narrow stone river-basin with amount rapids and sluices. 
</p>

<table cellspacing="0" cellpadding="4" border="1">
  <tbody>
    <tr align="center">
        <th>
            Difficulty
        </th>
        <th>
            Knowledge about control of boat</th>
        <th>
            Equipment
        </th>
    </tr>
    <tr>
        <td>ZWA
        </td>
        <td>nothing
        </td>
        <td>for all types of boats
        </td>
    </tr>
    <tr>
        <td>ZWB
        </td>
        <td>nothing
        </td>
        <td>for all types of boats
        </td>
    </tr>
    <tr>
        <td>ZWC
        </td>
        <td>basic knowledge about control of boat</td>
        <td>for all types of boats</td>
    </tr>
    <tr>
        <td>WW I
        </td>
        <td>basic knowledge about control of boat
        </td>
        <td>for all types of boats, jacket, helmet
        </td>
    </tr>
    <tr>
        <td>WW II
        </td>
        <td>good control of boat and knowledge about "reading of river"
        </td>
        <td>close boat to harder river, jacket, helmet
        </td>
    </tr>
    <tr>
        <td>WW III
        </td>
        <td>very good control</td>
        <td>raft or close boat, neopen, jacket, helmet
        </td>
    </tr>
    <tr>
        <td>WW IV
        </td>
        <td>very good control, traning</td>
        <td>raft or close boat, neopen, jacket, helmet
        </td>
    </tr>
    <tr>
        <td>WW V
        </td>
        <td>very good control, thews, training
        </td>
        <td>rraft or close boat, neopen, jacket, helmet</td>
    </tr>
    <tr>
        <td>WW VI</td>
        <td>very good control, thews, training
        </td>
        <td>raft or close boat, neopen, jacket, helmet
        </td>
    </tr>
  </tbody>
</table>
`