import React from 'react';

export default function Flag({ size }) {
  return (
    <svg width={size} height={size} viewBox="0 0 900 600" enable-background="new 0 0 900 600">
      <title>Flag of Slovakia</title>
      <rect y="0" fill="#EE1C25" width="900" height="600" />
      <rect y="0" fill="#0B4EA2" width="900" height="400" />
      <rect y="0" fill="#FFFFFF" width="900" height="200" />
      <g>
        <path
          id="_x3C_Path_x3E_"
          fill="#FFFFFF"
          d="M393.479,141h-8.103H269.917H154.461h-8.103l-0.847,8.059
		c-0.184,1.752-4.511,43.971-4.511,136.69c0,43.563,14.726,81.996,43.77,114.23c20.349,22.585,46.923,41.599,81.242,58.129
		l3.905,1.881l3.906-1.881c34.32-16.53,60.896-35.544,81.246-58.129c29.044-32.234,43.771-70.667,43.771-114.23
		c0-92.718-4.328-134.938-4.512-136.69L393.479,141L393.479,141z"
        />
        <path
          fill="#EE1C25"
          d="M269.917,450C220.577,426.234,150,379.062,150,285.749C150,192.436,154.461,150,154.461,150h115.456h115.459
		c0,0,4.463,42.437,4.463,135.749C389.839,379.062,319.261,426.234,269.917,450L269.917,450z"
        />
        <path
          fill="#FFFFFF"
          d="M280.484,261.284c13.36,0.216,39.425,0.739,62.635-7.027c0,0-0.613,8.306-0.613,17.98
		c0,9.677,0.613,17.982,0.613,17.982c-21.289-7.125-47.58-7.273-62.635-7.082v51.539H259.38v-51.539
		c-15.055-0.191-41.345-0.043-62.635,7.082c0,0,0.613-8.305,0.613-17.982c0-9.675-0.613-17.98-0.613-17.98
		c23.21,7.766,49.275,7.243,62.635,7.027v-32.368c-12.177-0.108-29.723,0.473-49.563,7.113c0,0,0.613-8.305,0.613-17.982
		c0-9.676-0.613-17.981-0.613-17.981c19.81,6.628,37.336,7.219,49.509,7.114c-0.626-20.498-6.597-46.332-6.597-46.332
		s12.289,0.959,17.202,0.959c4.918,0,17.203-0.959,17.203-0.959s-5.969,25.835-6.596,46.33c12.174,0.106,29.699-0.485,49.509-7.114
		c0,0-0.613,8.305-0.613,17.981c0,9.677,0.613,17.982,0.613,17.982c-19.839-6.639-37.386-7.221-49.563-7.113v32.368L280.484,261.284
		z"
        />
        <path
          fill="#0B4EA2"
          d="M269.904,329.094c-24.852,0-38.163,34.469-38.163,34.469s-7.385-16.344-27.661-16.344
		c-13.719,0-23.829,12.201-30.256,23.5c24.959,39.705,64.78,64.207,96.079,79.281c31.305-15.075,71.152-39.571,96.112-79.281
		c-6.427-11.299-16.537-23.5-30.256-23.5c-20.277,0-27.693,16.344-27.693,16.344S294.758,329.094,269.904,329.094L269.904,329.094z"
        />
      </g>
    </svg>
  );
}
