import React from 'react';

export default function Camp({ size = 30, color = 'black' }) {
  return (
    <svg version="1.0" width={size} height={size} viewBox="0 0 962 962" enable-background="new 0 0 962 962">
      <path
        stroke={color}
        fill={color}
        d="M523.7,218.719L603,97.918c10.6-16.199,6.1-37.899-10.1-48.5c-16.2-10.6-37.9-6.1-48.5,10.101l-62.601,95.5l-0.8-1.3
		l-1,1.5l-62.8-95.7c-10.601-16.2-32.3-20.7-48.5-10.101c-16.2,10.601-20.7,32.301-10.101,48.5l79.5,121.101l-392,597.3h178.3
		c9.7,0,19.4-1.6,28.5-4.899c143.6-52,198.7-213,218.3-298.2c2.399-10.4,17.1-10.4,19.5,0c19.5,85.1,74.7,246.1,218.3,298.2
		c9.1,3.3,18.8,4.899,28.5,4.899h178.3L523.7,218.719z"
      />
      <path
        stroke={color}
        fill={color}
        d="M935.8,848.318H26.2c-14.5,0-26.2,11.2-26.2,25v20c0,13.8,11.7,25,26.2,25h909.6c14.5,0,26.2-11.2,26.2-25v-20
		C962,859.519,950.3,848.318,935.8,848.318z"
      />
    </svg>
  );
}
