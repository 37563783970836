import React from 'react';
import AppLayout from '../layout/AppLayout';
import useReactRouter from 'use-react-router';
import { useRiverDetail } from '../datalayer/api';
import { extractRiverName, extractRiverDescription } from '../datalayer/extractors';
import { makeStyles } from '@material-ui/core/styles';
import useT from '../utility/useT';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
    // backgroundColor: theme.palette.background.paper,
  },
  header: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(1),
    // backgroundColor: theme.palette.background.paper,
  },
}));

export default function RiverDescriptionPage() {
  const { match } = useReactRouter();
  const { ID_reky } = match.params;
  const classes = useStyles();
  const t = useT();

  const riverDetail = useRiverDetail(ID_reky);
  const description = extractRiverDescription(riverDetail);
  const name = extractRiverName(riverDetail);

  return (
    <AppLayout title={name}>
      <div
        className={classes.wrapper}
        dangerouslySetInnerHTML={{ __html: `<b>${t('Conditions')} :</b> ` + description }}
      />
    </AppLayout>
  );
}
