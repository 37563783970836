import React from 'react';
import _ from 'lodash';
import Map from 'pigeon-maps';
import Marker, { CurrentPositionMarker } from './Marker';
import AppBarMeasure from '../ui-elements/AppBarMeasure';
import useWindowSize from '../utility/useWindowSize';
import useViewportWidth from '../utility/useViewportWidth';
import useLocation from '../utility/useLocation';
import { makeStyles } from '@material-ui/core/styles';
import ErrorInfo from '../ui-elements/ErrorInfo';
import useT from '../utility/useT';
import LocationFab from './LocationFab';

export const mapProviders = {
  osm: (x, y, z) => {
    const s = String.fromCharCode(97 + ((x + y + z) % 3));
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
  },
  wikimedia: (x, y, z, dpr) => {
    return `https://maps.wikimedia.org/osm-intl/${z}/${x}/${y}${dpr >= 2 ? '@2x' : ''}.png`;
  },
  mapycz: (x, y, z) => {
    return `https://mapserver.mapy.cz/base-m/${z}-${x}-${y}`;
  },
  mapycz_turist: (x, y, z) => {
    return `https://mapserver.mapy.cz/turist-m/${z}-${x}-${y}`;
  },
  mapycz_photo: (x, y, z) => {
    // return `https://mapserver.mapy.cz/bing/${z}-${x}-${y}`;
    // return google maps instead
    return `https://khms3.google.com/kh/v=917?x=${x}&y=${y}&z=${z}`;
  },
  maps_google_photo: (x, y, z) => {
    return `https://khms3.google.com/kh/v=917?x=${x}&y=${y}&z=${z}`;
  },
};

export const mapAttribution = {
  mapycz: (
    <span>
      <a
        href="https://mapy.cz"
        style={{
          color: '#0078A8',
          textDecoration: 'none',
        }}
      >
        mapy.cz
      </a>
    </span>
  ),
};

const useStyles = makeStyles((theme) => ({
  currentIconWrapper: {
    margin: 'auto',
  },
}));

function convertPoint(point, extractPoint) {
  if (extractPoint) point = extractPoint(point);
  return [point.latitude, point.longitude];
}

function PointsMap({
  onClick = null,
  points = [],
  isHighlighted = null,
  extractPoint = undefined,
  onMarkerClick = undefined,
  extractImage = undefined,
}) {
  const classes = useStyles();
  // console.log('points', points);
  // console.log('isHighlighted', isHighlighted);
  // const size = useViewportSize();
  // const coords = locations.map(location => location.coordinates);
  const [appBarHeight, setAppBarHeight] = React.useState(0);
  const winSize = useWindowSize();
  const location = useLocation();
  const width = useViewportWidth();
  const t = useT();

  const highlighted = isHighlighted ? points.find(isHighlighted) : undefined;

  const getDefaultCenter = () =>
    highlighted
      ? convertPoint(highlighted, extractPoint)
      : points.length > 0
      ? convertPoint(points[0], extractPoint)
      : [14.4248281, 50.1129869];

  const [center, setCenter] = React.useState(getDefaultCenter);
  const [zoom, setZoom] = React.useState(12);

  const handleBoundsChange = ({ center, zoom, bounds, initial }) => {
    setCenter(center);
    setZoom(zoom);
  };

  return width > 0 && winSize.height > 0 && points.length > 0 && appBarHeight > 0 ? (
    <>
      <Map
        center={center}
        zoom={zoom}
        onBoundsChanged={handleBoundsChange}
        defaultWidth={width}
        // must be set defaultWidth instead of width, otherwise mouse wheel zoom will not work
        // width={width}

        height={winSize.height - appBarHeight}
        provider={mapProviders.osm}
        attribution={mapAttribution.mapycz}
        onClick={onClick}
      >
        {points.map((point, index) => (
          <Marker
            anchor={convertPoint(point, extractPoint)}
            key={index}
            isHighlighted={isHighlighted && isHighlighted(point)}
            onClick={onMarkerClick ? () => onMarkerClick(point) : undefined}
            image={extractImage ? extractImage(point) : undefined}
          />
        ))}
        {location && location.latitude > 0 && location.longitude > 0 && (
          <CurrentPositionMarker anchor={[location.latitude, location.longitude]} />
        )}
      </Map>
      <AppBarMeasure onChangeHeight={setAppBarHeight} />
      <LocationFab
        hasGps={location != null && location.latitude > 0 && location.longitude > 0}
        onClickLocation={() => {
          setCenter([location.latitude, location.longitude]);
        }}
      />
    </>
  ) : (
    <div>
      <ErrorInfo message={t('NoMapData')} />
      <AppBarMeasure onChangeHeight={setAppBarHeight} />
    </div>
  );
}

export default PointsMap;
