import cs from './explanationsCs';
import en from './explanationsEn';

export default {
  cs,
  en,
  pl: cs,
  sk: cs,
  de: en,
};
