import _ from 'lodash';
import React from 'react';
import Fab from '@material-ui/core/Fab';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import LocationDisabledIcon from '@material-ui/icons/LocationDisabled';
import { makeStyles } from '@material-ui/core/styles';
import { useWatchPosition, useSetWatchPosition } from '../utility/globalState';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    right: '1rem',
    bottom: '1rem',
  },
})); 

export default function LocationFab({ onClickLocation, hasGps }) {
  const classes = useStyles();
  const watchPosition = useWatchPosition();
  const setWatchPosition = useSetWatchPosition();

  return (
    <>
      {' '}
      {hasGps && (
        <Fab color="secondary" aria-label="Add" className={classes.fab} onClick={onClickLocation}>
          <MyLocationIcon />
        </Fab>
      )}
      {watchPosition == 'true' && !hasGps && (
        <Fab
          aria-label="Location"
          className={classes.fab}
          onClick={() => {
            setWatchPosition('false');
          }}
        >
          <LocationSearchingIcon />
        </Fab>
      )}
      {watchPosition == 'false' && (
        <Fab
          aria-label="Location"
          className={classes.fab}
          onClick={() => {
            setWatchPosition('true');
          }}
        >
          <LocationDisabledIcon />
        </Fab>
      )}
    </>
  );
}
