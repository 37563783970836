import React from 'react';

export default function Canoe({ size = 30 }) {
  return (
    <svg version="1.0" width={size} height={size} viewBox="0 0 100 47.969" enable-background="new 0 0 100 47.969">
      <g>
        <path
          d="M78.613,0c-2.663,0-4.831,2.144-4.831,4.807c0,2.674,2.168,4.831,4.831,4.831c2.662,0,4.83-2.156,4.83-4.831
		C83.443,2.144,81.275,0,78.613,0z"
        ></path>
        <path d="M78.613,4.807"></path>
      </g>
      <g>
        <path
          d="M38.112,0c-2.662,0-4.831,2.144-4.831,4.807c0,2.674,2.168,4.831,4.831,4.831c2.663,0,4.831-2.156,4.831-4.831
		C42.943,2.144,40.775,0,38.112,0z"
        ></path>
        <path d="M38.112,4.807"></path>
      </g>
      <path
        d="M99.297,32.767c0,0-1.313-5.505-6.156-10.445c0,0-0.307,6.667-24.362,9.121c-0.848-0.998-1.405-2.696,2.137-4.386
	l7.372,2.517c0,0,1.542-0.107,0.88-1.746l-4.734-2.65c0,0-0.987-1.542-0.108-2.084c0.879-0.554,1.65-1.771,1.65-1.771
	s1.205-0.771,2.529-0.325c1.325,0.445,2.193-0.446,2.867-1.096l3.855-3.976c0,0,1.975-1.638,2.854-1.518
	c0.88,0.097,0.217-2.554,0.217-2.554s-4.071,1.879-6.276,3.747c0,0-1.639,1.434-2.854-0.325c0,0-4.181-4.518-5.505-5.072
	c0,0-1.434-1.313-3.301,0.35c0,0-5.614,3.505-9.686,9.999c0,0-4.353,6.678-3.508,11.645c-2.244,0.076-4.633,0.121-7.166,0.133
	c-9.125-0.044-16.324-0.525-21.997-1.256c-0.648-1.018-0.727-2.521,2.41-4.017l7.372,2.517c0,0,1.542-0.107,0.879-1.746l-4.734-2.65
	c0,0-0.988-1.542-0.108-2.084c0.879-0.554,1.65-1.771,1.65-1.771s1.205-0.771,2.53-0.325c1.325,0.445,2.193-0.446,2.867-1.096
	l3.855-3.976c0,0,1.976-1.638,2.855-1.518c0.88,0.097,0.217-2.554,0.217-2.554s-4.072,1.879-6.276,3.747
	c0,0-1.638,1.434-2.855-0.325c0,0-4.18-4.518-5.505-5.072c0,0-1.434-1.313-3.301,0.35c0,0-5.614,3.505-9.686,9.999
	c0,0-2.681,4.112-3.429,8.282c-9.724-2.887-9.888-6.511-9.888-6.511c-4.843,4.939-6.162,10.445-6.162,10.445
	c-2.969,8.48,4.511,13.094,4.511,13.094c5.722,3.65,8.523-2.252,8.523-2.252c1.265,1.795,3.53,4.36,6.107,4.36
	c2.53,0,4.734-2.565,6.011-4.325c1.289,1.711,3.409,4.181,5.915,4.181c2.662,0,4.915-2.53,6.144-4.457
	c1.277,1.831,3.458,4.457,6.047,4.457s4.771-2.518,6.035-4.337c1.266,1.807,3.434,4.409,6.023,4.409c2.541,0,4.697-2.481,5.975-4.24
	c1.289,1.747,3.434,4.312,5.963,4.312c2.675,0,4.963-2.577,6.192-4.518c1.265,1.808,3.47,4.445,6.06,4.445
	c2.518,0,4.758-2.53,6.047-4.265c0,0,2.818,5.879,8.541,2.229C94.791,45.86,102.283,41.247,99.297,32.767z"
      ></path>
    </svg>
  );
}
