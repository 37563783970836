import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { isAndroid } from 'react-device-detect';
import MapIcon from '@material-ui/icons/Map';
import ChooseFromListDialog from '../ui-elements/ChooseFromListDialog';
import useT from '../utility/useT';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: '20px',
  },
}));

export default function GpsDetailDialog({ dialogProps, coordinates }) {
  const t = useT();
  const classes = useStyles();
  if (!coordinates) return null;

  const menuItems = [
    {
      text: t('OpenGoogleMaps'),
      icon: DirectionsCarIcon,
      link: `http://maps.google.com/maps?daddr=${coordinates.latitude},${coordinates.longitude}`,
    },
  ];
  if (isAndroid) {
    menuItems.push({
      text: t('ChooseMap'),
      icon: MapIcon,
      link: `geo:${coordinates.latitude},${coordinates.longitude}`,
    });
  }

  return (
    <ChooseFromListDialog items={menuItems} {...dialogProps}>
      <TextField
        value={`${Math.round(coordinates.latitude * 1000000) / 1000000},${
          Math.round(coordinates.longitude * 1000000) / 1000000
        }`}
        className={classes.textField}
      />
    </ChooseFromListDialog>
  );
}
