import React from 'react';
import _ from 'lodash';
import {
  extractKnownSections,
  parseGauges,
  extractRiverName,
  extractIdZeme,
  extractRiverSectionsBySemaphore,
  extractRiverGuide,
  extractDifficultySections,
} from '../datalayer/extractors';
import List from '@material-ui/core/List';
import { useRiverDetail, useCountryGauges } from '../datalayer/api';
import useReactRouter from 'use-react-router';
import LoadingInfo from '../ui-elements/LoadingInfo';
import ErrorInfo, { extractErrorMessage } from '../ui-elements/ErrorInfo';
import GaugeListItem from './GaugeListItem';
import { useSelectedSection, useSetSelectedSection } from '../utility/globalState';
import AppLayout from '../layout/AppLayout';
import useT from '../utility/useT';

export default function RiverGaugesPage() {
  const { match, history } = useReactRouter();
  const { ID_reky } = match.params;

  const riverDetail = useRiverDetail(ID_reky);
  const name = extractRiverName(riverDetail);
  const section = useSelectedSection(ID_reky);
  const setSection = useSetSelectedSection(ID_reky);

  const countryGauges = useCountryGauges(extractIdZeme(riverDetail));
  const gaugesData = extractRiverSectionsBySemaphore(countryGauges, ID_reky);
  const gauges = parseGauges(gaugesData);
  const guide = extractRiverGuide(riverDetail);
  const isGuide = guide.length > 0;
  const difficultySections = extractDifficultySections(riverDetail);

  const subMenuItems = [];
  const t = useT();

  const handleClick = (section) => {
    if (!isGuide) return;
    const { range, od } = section;
    setSection({
      ...range,
      gaugeSectionName: od,
    });
    history.goBack();
    // showGuide();
  };

  return (
    <AppLayout
      title={`${name} - ${isGuide ? t('chooseSection') : t('Sections').toLocaleLowerCase()}`}
      subMenuItems={subMenuItems}
    >
      {countryGauges && countryGauges.state == 'pending' ? (
        <LoadingInfo message={t('LoadingGauges')} />
      ) : countryGauges && countryGauges.state == 'rejected' ? (
        <ErrorInfo message={countryGauges.error && extractErrorMessage(countryGauges)} />
      ) : (
        <List>
          {gauges.map((x) => (
            <GaugeListItem
              showRadio
              showChart
              isGuide={isGuide}
              gauge={x}
              handleClick={handleClick}
              section={section}
              difficultySections={difficultySections}
            />
          ))}
        </List>
      )}
    </AppLayout>
  );
}
