import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { parseGps } from '../datalayer/extractors';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import Button from '@material-ui/core/Button';
import useStateDialog from '../utility/useStateDialog';
import GpsDetailDialog from './GpsDetailDialog';

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export function DriveToButton({ coordinates, className = undefined }) {
  const [openDialog, dialogProps] = useStateDialog();
  if (!coordinates) return null;

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={className}
        // @ts-ignore
        onClick={() => openDialog()}
      >
        <DirectionsCarIcon />
      </Button>
      <GpsDetailDialog coordinates={coordinates} dialogProps={dialogProps} />
    </>
  );
}

export default function GpsView({ name, gps }) {
  const classes = useStyles();
  if (!gps) return null;
  const coordinates = parseGps(gps);
  if (!coordinates) return null;
  return (
    <div className={classes.root}>
      <div className={classes.grow}>
        <span className={classes.bold}>{name}: </span>
        {Math.round(coordinates.latitude * 10000) / 10000}, {Math.round(coordinates.longitude * 10000) / 10000}
      </div>
      <DriveToButton className={classes.button} coordinates={coordinates} />
    </div>
  );
}
