import React from 'react';
import useT from '../utility/useT';
import AsyncDataWrapper from '../ui-elements/AsyncDataWrapper';

export default function RiverDetailWrapper({ riverDetail, children }) {
  const t = useT();
  return (
    <AsyncDataWrapper dataHolder={riverDetail} loadingMessage={t('LoadingRiverDetail')}>
      {children}
    </AsyncDataWrapper>
  );
}
