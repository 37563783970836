export default function containsText(searched, ...tokens) {
  if (!searched) return true;
  const searchedNorm = searched
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  for (const token of tokens) {
    if (!token) continue;
    const tokenNorm = token
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    if (tokenNorm.includes(searchedNorm)) return true;
  }
  return false;
}
