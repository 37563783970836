import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MarkerIcon from '../icons/Marker';
import clsx from 'clsx';
import MyLocationIcon from '@material-ui/icons/MyLocation';

const useStyles = makeStyles((theme) => ({
  marker: {
    position: 'absolute',
    transform: `translate(-20px, -40px)`,
  },
  currentMarker: {
    position: 'absolute',
    transform: `translate(-10px, -10px)`,
    color: 'blue',
  },
}));

export default function Marker({
  isHighlighted = false,
  anchor = undefined,
  onClick = undefined,
  left = undefined,
  top = undefined,
  image = undefined,
}) {
  const classes = useStyles();
  const style = {
    left,
    top,
    cursor: onClick ? 'pointer' : 'default',
  };

  return (
    // @ts-ignore
    <div style={style} className={clsx('pigeon-click-block', classes.marker)} onClick={onClick}>
      {image ? (
        <img src={`${process.env.REACT_APP_ROUTE_BASE}/map-icons/${image}.gif`} alt={image} />
      ) : (
        <MarkerIcon size={40} color={isHighlighted ? 'blue' : 'red'} />
      )}
    </div>
  );
}

export function CurrentPositionMarker({ anchor = undefined, left = undefined, top = undefined }) {
  const classes = useStyles();

  const style = {
    left,
    top,
  };

  return (
    // @ts-ignore
    <div style={style} className={clsx('pigeon-click-block', classes.currentMarker)}>
      <MyLocationIcon />
    </div>
  );
}
