export function distance(lat1, lon1, lat2, lon2, unit = 'K') {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    return dist;
  }
}

export function distanceGps(gps1, gps2) {
  return distance(gps1.latitude, gps1.longitude, gps2.latitude, gps2.longitude);
}

export function rangeIntersection(range1, range2) {
  const { begin: ae, end: as } = range1;
  const { begin: be, end: bs } = range2;

  if (bs > ae || as > be) return null;
  const os = Math.max(as, bs);
  const oe = Math.min(ae, be);
  return {
    begin: oe,
    end: os,
  };
}

export function rangeIntersectionLength(range1, range2) {
  const res = rangeIntersection(range1, range2);
  if (res) {
    return res.begin - res.end;
  }
  return 0;
}

export function roundKm(value) {
  return Math.round(value * 10) / 10;
}

export function formatDistance(km1, km2) {
  const dist = Math.abs(km1 - km2);
  if (dist < 1) return `${Math.round(dist * 10) * 100} m`;
  return `${Math.round(dist * 10) / 10} km`;
}
