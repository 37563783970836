import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import AppLayout from '../layout/AppLayout';
import useReactRouter from 'use-react-router';
import { useRiverDb, useCountryGauges } from '../datalayer/api';
import SearchField, { getSearchValue } from '../ui-elements/SearchField';
import containsText from '../utility/containsText';
import RiverList from './RiverList';
import AsyncDataWrapper, { combineDataHolders } from '../ui-elements/AsyncDataWrapper';
import useT from '../utility/useT';
import { extractRiverSemaphoreByRiver } from '../datalayer/extractors';
import { useSetRiverFilter, useRiverFilter } from '../utility/globalState';
import localeSort from '../utility/localeSort';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  searchDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  flex1: {
    flex: '1',
  },
}));

export default function RiverListPage() {
  const { history, match } = useReactRouter();
  const [filter, setFilter] = React.useState(getSearchValue(history, 'rivers'));
  const t = useT();
  const riverDb = useRiverDb();
  const { Staty, Reky } = riverDb;
  const { ID_zeme } = match.params;
  const rivers = Reky.filter((x) => x.ID_zeme == ID_zeme);
  const country = Staty.find((x) => x.ID_zeme == ID_zeme) || {};
  const countryGauges = useCountryGauges(ID_zeme);
  const riverSemaphores = extractRiverSemaphoreByRiver(countryGauges);
  const classes = useStyles();

  const riverFilter = useRiverFilter();
  const setRiverFilter = useSetRiverFilter();

  const handleRunFilter = (filter) => {
    setFilter(filter);
  };

  const riverFilterSetter = (name) => (value) => setRiverFilter((x) => ({ ...x, [name]: value }));

  const subMenuItems = [];

  const { onlyRunnable, showRivers, showCreeks, showChannels, showBeginner, showAdvanced, showExpert } = riverFilter;

  subMenuItems.push({
    label: t('OnlyRunnable'),
    checkboxValue: onlyRunnable,
    checkboxSetter: riverFilterSetter('onlyRunnable'),
  });
  subMenuItems.push({
    label: t('Rivers'),
    checkboxValue: showRivers,
    checkboxSetter: riverFilterSetter('showRivers'),
  });
  subMenuItems.push({
    label: t('Creeks'),
    checkboxValue: showCreeks,
    checkboxSetter: riverFilterSetter('showCreeks'),
  });
  subMenuItems.push({
    label: t('Channels'),
    checkboxValue: showChannels,
    checkboxSetter: riverFilterSetter('showChannels'),
  });
  subMenuItems.push({
    label: t('Beginner'),
    checkboxValue: showBeginner,
    checkboxSetter: riverFilterSetter('showBeginner'),
  });
  subMenuItems.push({
    label: t('Advanced'),
    checkboxValue: showAdvanced,
    checkboxSetter: riverFilterSetter('showAdvanced'),
  });
  subMenuItems.push({
    label: t('Expert'),
    checkboxValue: showExpert,
    checkboxSetter: riverFilterSetter('showExpert'),
  });

  const filterRiverFunc = (river) => {
    if (!containsText(filter, river.Nazev)) return false;
    if (showRivers == 'false' && river.Reka == 1) return false;
    if (showCreeks == 'false' && river.Reka == 0) return false;
    if (showChannels == 'false' && river.Reka == 2) return false;

    if (onlyRunnable == 'true' && riverSemaphores) {
      const { ID_reky } = river;
      const runnable = riverSemaphores[ID_reky] == 1 || riverSemaphores[ID_reky] == 2;
      if (!runnable) return false;
    }

    if (showBeginner == 'false' || showAdvanced == 'false' || showExpert == 'false') {
      if (showBeginner == 'true') {
        if (river.H_obtiznost == 'Z' || river.S_obtiznost == 'Z' || river.D_obtiznost == 'Z') return true;
      }
      if (showAdvanced == 'true') {
        if (river.H_obtiznost == 'S' || river.S_obtiznost == 'S' || river.D_obtiznost == 'S') return true;
      }
      if (showExpert == 'true') {
        if (river.H_obtiznost == 'E' || river.S_obtiznost == 'E' || river.D_obtiznost == 'E') return true;
      }
      return false;
    }
    return true;
  };

  const handleClearFilter = () => {
    setRiverFilter({
      onlyRunnable: 'false',

      showRivers: 'true',
      showCreeks: 'true',

      showBeginner: 'true',
      showAdvanced: 'true',
      showExpert: 'true',
    });
    setFilter('');
    history.replace(history.location.pathname, { ...history.location.state, rivers_search: '' });
  };

  return (
    <AppLayout title={country.Nazev} subMenuItems={subMenuItems}>
      <div className={classes.searchDiv}>
        <SearchField routerStateField="rivers" onRunFilter={handleRunFilter} className={classes.flex1} />
        {(onlyRunnable != 'false' ||
          showRivers != 'true' ||
          showCreeks != 'true' ||
          showBeginner != 'true' ||
          showAdvanced != 'true' ||
          showExpert != 'true' ||
          filter) && (
            <IconButton onClick={handleClearFilter}>
              <ClearIcon />
            </IconButton>
          )}
      </div>
      <AsyncDataWrapper
        dataHolder={onlyRunnable == 'true' ? combineDataHolders(riverDb, countryGauges) : riverDb}
        loadingMessage={t('LoadingRivers')}
      >
        <RiverList rivers={localeSort(rivers, 'Nazev').filter(filterRiverFunc)} riverSemaphores={riverSemaphores} />
      </AsyncDataWrapper>
    </AppLayout>
  );
}
