import localforage from 'localforage';
import usePromise from 'react-use-promise';
import { useSnackbar } from 'notistack';
import useLanguage from '../utility/useLanguage';
import useT from '../utility/useT';
import _ from 'lodash';
import { extractRiverGuide } from './extractors';
import { addGpsToGuide } from './guideGps';
import { getOnlineStatus } from '../utility/useOnlineStatus';

const maxAgeMsLong = 7 * 24 * 3600 * 1000; // one week
const maxAgeMsFresh = 3600 * 1000; // one hour

const lastChangedApiFormat = new Date('2020-05-14T05:56:24.554Z').getTime();

export async function getCachedResourceFromApiCore(path, cacheName, onSaveToCache, transform = (x) => x) {
  const isOnline = getOnlineStatus();
  if (!isOnline) {
    throw new Error('t:ApplicationIsOffline');
  }
  const resp = await fetch(`${process.env.REACT_APP_API_URL}/${path}`);
  const json = transform(await resp.json());
  localforage.setItem(cacheName, {
    ...json,
    downloadDate: new Date().toISOString(),
    resourcePath: path,
  });
  if (onSaveToCache) onSaveToCache(json);
  return json;
}

export async function getCachedResourceFromApi(path, cacheName, requireFreshData, onSaveToCache, transform) {
  const cached = await localforage.getItem(cacheName);
  if (cached) {
    const isOnline = getOnlineStatus();
    if (!isOnline) return cached;

    const { downloadDate } = cached;

    if (!downloadDate || new Date(downloadDate).getTime() < lastChangedApiFormat) {
      return getCachedResourceFromApiCore(path, cacheName, onSaveToCache, transform);
    }

    const diff = downloadDate == null ? null : new Date().getTime() - new Date(downloadDate).getTime();

    if (requireFreshData) {
      if (diff == null || diff > maxAgeMsFresh) {
        return getCachedResourceFromApiCore(path, cacheName, onSaveToCache, transform);
      }
      return cached;
    } else {
      if (diff == null || diff > maxAgeMsLong) {
        getCachedResourceFromApiCore(path, cacheName, onSaveToCache, transform);
      }
      return cached;
    }
  }

  return getCachedResourceFromApiCore(path, cacheName, onSaveToCache, transform);
}

function getApiLanguage(language) {
  switch (language) {
    case 'en':
      return 'en-US';
    case 'de':
      return 'de-DE';
    case 'sk':
      return 'sk-SK';
    case 'pl':
      return 'pl-PL';
    default:
      return 'cs-CZ';
  }
}

export async function getRiverDb(language) {
  return await getCachedResourceFromApi(
    `mobil_vodocet.ashx?reky=1&jazyk=${getApiLanguage(language)}`,
    `riverdb-${language}`
  );
}

export function useRiverDb() {
  const language = useLanguage();
  const [result, error, state] = usePromise(() => getRiverDb(language), [language]);
  if (result && result.Staty && result.Reky) return result;
  return {
    Staty: [],
    Reky: [],
    state,
    error,
  };
}

function addGpsToRiver(riverDetail) {
  const guide = extractRiverGuide(riverDetail);
  addGpsToGuide(guide);
  return riverDetail;
}

export async function getRiverDetail(ID_reky, language, onSaveToCache) {
  return await getCachedResourceFromApi(
    `mobil_vodocet.ashx?ID_reky=${ID_reky}&jazyk=${getApiLanguage(language)}`,
    `river-${language}-${ID_reky}`,
    false,
    onSaveToCache,
    addGpsToRiver
  );
}

export function useRiverDetail(ID_reky) {
  const t = useT();
  const language = useLanguage();
  const { enqueueSnackbar } = useSnackbar();
  const [result, error, state] = usePromise(
    () => getRiverDetail(ID_reky, language, () => enqueueSnackbar(t('RiverSavedToOffline'), { variant: 'info' })),
    [ID_reky, language]
  );

  return result || { error, state };
}

// export async function getRiverGauges(ID_reky, onSaveToCache) {
//   return await getCachedResourceFromApi(`export_reky.ashx?ID_reky=${ID_reky}`, `gauge-${ID_reky}`, true, onSaveToCache);
// }

// export function useRiverGauges(ID_reky) {
//   const [result, error, state] = usePromise(() => getRiverGauges(ID_reky), [ID_reky]);

//   return result || { error, state };
// }

export async function getCountryGauges(ID_zeme, onSaveToCache) {
  if (!ID_zeme)
    return {
      state: 'pending',
    };
  return await getCachedResourceFromApi(
    `export_reky.ashx?ID_zeme=${ID_zeme}`,
    `gauge-country-${ID_zeme}`,
    true,
    onSaveToCache
  );
}

export function useCountryGauges(ID_zeme) {
  const [result, error, state] = usePromise(() => getCountryGauges(ID_zeme), [ID_zeme]);

  return result || { error, state };
}

export async function getRiverGauges(ID_reky, onSaveToCache) {
  if (!ID_reky)
    return {
      state: 'pending',
    };
  return await getCachedResourceFromApi(
    `export_reky.ashx?ID_reky=${ID_reky}`,
    `gauge-river-${ID_reky}`,
    true,
    onSaveToCache
  );
}

export function useRiverGauges(ID_reky) {
  const [result, error, state] = usePromise(() => getRiverGauges(ID_reky), [ID_reky]);

  return result || { error, state };
}

function dataUrltoBlob(dataurl) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export async function sendFeedback({ ID_reky = '0', popis, gps = null, autor, rkm = null, imageUrls = [] }) {
  var body = new FormData();
  body.append('ID_reky', ID_reky);
  body.append('popis', popis);
  body.append('gps', gps);
  body.append('autor', autor);
  body.append('rkm', rkm);
  let photoIndex = 1;
  for (const imageUrl of imageUrls) {
    body.append(`photo${photoIndex}`, dataUrltoBlob(imageUrl));
    photoIndex += 1;
  }

  const resp = await fetch(`${process.env.REACT_APP_API_URL}/upload_mobil.ashx`, {
    body,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // },
    method: 'post',
  });
  const json = await resp.json();
  return json.Feedback;
}
