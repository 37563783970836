import _ from 'lodash';
import React from 'react';
import AppLayout from '../layout/AppLayout';
import MailIcon from '@material-ui/icons/Mail';
import LanguageIcon from '@material-ui/icons/Language';
import { makeStyles } from '@material-ui/core/styles';
import useT from '../utility/useT';
import InfoBox from '../ui-elements/InfoBox';
import Panel from '../ui-elements/Panel';
import IconLink from '../ui-elements/IconLink';

const useStyles = makeStyles((theme) => ({
  linkitem: {
    margin: theme.spacing(2),
    display: 'flex',
  },
}));


export default function AboutPage() {
  const t = useT();
  return (
    <AppLayout title={t('About')}>
      <InfoBox message={t('WarrantyInfo')} />
      <Panel>
          <IconLink Icon={MailIcon} link='mailto:info@raft.cz'>info@raft.cz</IconLink>
          <IconLink Icon={LanguageIcon} link='https://www.raft.cz'>www.raft.cz</IconLink>
      </Panel>
    </AppLayout>
  );
}
