import _ from 'lodash';
import { rangeIntersection } from '../utility/mathutil';

export function extractRiverName(riverDetail) {
  return _.get(riverDetail, 'ExportReky.reka.@nazev') || '';
}

export function extractRiverId(riverDetail) {
  return _.get(riverDetail, 'ExportReky.reka.ID_reky') || null;
}

export function extractRiverDescription(riverDetail) {
  return _.get(riverDetail, 'ExportReky.reka.popis') || '';
}

export function filterGuideBySection(guide, section) {
  if (!section) return guide;
  const { begin, end } = section;
  return guide.filter((rkm) => {
    const km = extractRkmKm(rkm);
    return km <= begin && km >= end;
  });
}

export function extractRiverGuide(riverDetail, section = undefined) {
  const res = _.get(riverDetail, 'ExportReky.reka.kilometraze.rkm') || [];
  return filterGuideBySection(res, section);
}

export function extractNextRkmDetail(guide, rkmDetail) {
  const rkm = extractRkmKm(rkmDetail);
  return guide.find((x) => extractRkmKm(x) < rkm);
}

export function extractBeforeRkmDetail(guide, rkmDetail) {
  const rkm = extractRkmKm(rkmDetail);
  return _.findLast(guide, (x) => extractRkmKm(x) > rkm);
}

// function enhanceSectionBorders(items, knownSections, field) {
//   for (const known of knownSections) {
//     if (items.find((x) => x.km == known.range[field])) continue;
//     items.push({ name: known.name, km: known.range[field] });
//   }
//   return _.sortBy(items, (x) => -x.km);
// }

export function extractRiverBegins(riverDetail, t) {
  const guide = extractRiverGuide(riverDetail);
  if (guide && guide[0]) {
    const res = guide
      .filter((x) => x.zacatek && x.zacatek.Popis)
      .map((x) => ({ name: x.zacatek.Popis, km: extractRkmKm(x) }));
    const begin = extractRkmKm(guide[0]);
    if (res.find((x) => x.km == begin)) return res;
    return [{ name: t('RiverBegin'), km: begin }, ...res];
    // return enhanceSectionBorders(
    //   [{ name: 'Zacatek reky', km: extractRkmKm(guide[0]) }, ...res],
    //   knownSections,
    //   'begin'
    // );
  }
  return [];
}

export function extractRiverImage(riverDetail) {
  const guide = extractRiverGuide(riverDetail);
  const photos = _.flatten(guide.map((rkm) => extractPhotos(rkm))).filter((x) => x['photo_file']);
  // const index = Math.floor(Math.random() * photos.length);
  const index = 0;
  if (photos.length > 0) return photos[index]['photo_file'];
  return null;
}

export function extractRiverEnds(riverDetail, t) {
  const guide = extractRiverGuide(riverDetail);
  if (guide && guide[0]) {
    const res = guide
      .filter((x) => x.konec && x.konec.Popis)
      .map((x) => ({ name: x.konec.Popis, km: extractRkmKm(x) }));
    if (res.find((x) => x.km == 0)) return res;
    return [...res, { name: t('RiverEnd'), km: 0 }];
    // return enhanceSectionBorders([...res, { name: 'Konec reky', km: 0 }], knownSections, 'end');
  }
  return [];
}

export function extractFullRiverSection(riverDetail) {
  const guide = extractRiverGuide(riverDetail);
  if (guide && guide[0]) {
    return {
      begin: extractRkmKm(guide[0]),
      end: 0,
    };
  }
  return {
    start: 0,
    end: 0,
  };
}

export function extractMapPointsFromGuide(guide, shownObjects = null) {
  const res = [];
  for (const rkmDetail of guide) {
    const rkmPoints = extractMapPoints(rkmDetail, null, shownObjects);
    res.push(...rkmPoints);
  }
  return res;
}

export function parseGps(gps) {
  if (_.isPlainObject(gps) && gps.latitude && gps.longitude) return gps;
  if (_.isArray(gps)) gps = gps[0];
  if (gps) {
    const [latitude, longitude] = gps.split(',').map(parseFloat);
    return { latitude, longitude };
  }
  return null;
}

export function extractPhotos(rkmDetail) {
  if (!rkmDetail) return [];
  const { photo } = rkmDetail;
  const photos = _.isArray(photo) ? photo : _.isPlainObject(photo) ? [photo] : [];
  return photos.map((x) => ({
    photo_title: x.photo_title,
    photo_file: x.photo_file,
  }));
}

export function extractRkmLinks(rkmDetail) {
  if (!rkmDetail) return [];
  const { link } = rkmDetail;
  const links = _.isArray(link) ? link : _.isPlainObject(link) ? [link] : [];
  return links.map((x) => ({
    text: x['#text'],
    typ: x['@typ'],
  }));
}

export function extractRkmDescription(rkmDetail) {
  return rkmDetail && rkmDetail.popis;
}

export function extractRkmTitle(rkmDetail) {
  const maxLength = 20;
  const description = extractRkmDescription(rkmDetail);
  const words = _.words(description && description.replace(/<\/?[^>]+(>|$)/g, ''));
  if (!words || !words[0]) return null;
  let res = words[0];
  let len = res.length;
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const nextWord = words[i + 1] || '';

    if (word.length <= 2) {
      if (len + word.length + nextWord.length <= maxLength) {
        res = `${res} ${word}`;
        len += word.length;
      } else {
        return res;
      }
    } else {
      if (len + word.length <= maxLength) {
        res = `${res} ${word}`;
        len += word.length;
      } else {
        return res;
      }
    }
  }
  return res;
}

export function extractRkmIconDescription(rkmDetail) {
  return rkmDetail && rkmDetail.popis_icon;
}

export function extractRkmIcons(rkmDetail) {
  return (rkmDetail.ikonky || '')
    .split(',')
    .map((x) => x.trim())
    .filter((x) => x);
}

export function extractRkmKm(rkmDetail) {
  if (!rkmDetail) return null;
  if (_.isNumber(rkmDetail.rkm)) return rkmDetail.rkm;
  const km = parseFloat(rkmDetail.km.replace(',', '.'));
  return km;
}

function convertPoint(point, res, icon, rkmDetail, title) {
  if (!point) return;
  if (_.isArray(point)) {
    point.forEach((x) => convertPoint(x, res, icon, rkmDetail, title));
  }
  const gps = parseGps(point.gps);
  if (!gps) return;
  res.push({
    title,
    gps,
    icon,
    point,
    rkmDetail,
  });
}

export function extractMapPoints(rkmDetail, camps = null, shownObjects = null) {
  const test = (name) => !shownObjects || shownObjects[name];
  const res = [];
  if (!rkmDetail) return [];
  if (camps !== true) {
    if (test('jez-x')) convertPoint(rkmDetail.jez, res, 'jez-x', rkmDetail, 'Jez');
    if (test('zacatek_konec')) convertPoint(rkmDetail.zacatek, res, 'zacatek', rkmDetail, 'Začátek');
    if (test('zacatek_konec')) convertPoint(rkmDetail.konec, res, 'konec', rkmDetail, 'Konec');

    if (test('most')) convertPoint(rkmDetail.most, res, 'most', rkmDetail, 'Most');
    if (test('jidlo')) convertPoint(rkmDetail.obcerstveni, res, 'jidlo', rkmDetail, 'Občerstvení');
    if (test('pritok')) convertPoint(rkmDetail.pritok, res, 'pritok', rkmDetail, 'Přítok');
    if (test('_other')) convertPoint(rkmDetail.zajimavost, res, 'zajim', rkmDetail, 'Zajímavost');
  }
  if (camps !== false) {
    if (test('taboriste')) convertPoint(rkmDetail.taboriste, res, 'taboriste', rkmDetail, 'Tábořiště');
  }
  return res;
}

export function extractKnownSections(riverDetail) {
  const data = _.get(riverDetail, 'ExportReky.reka.usek_obtiznosti') || '';
  if (!data) return [];
  var parser = new DOMParser();
  var htmlDoc = parser.parseFromString(`<table>${data}</table>`, 'text/html');

  const rows = htmlDoc.getElementsByTagName('tr');
  const res = [];
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    try {
      const tr = rows[rowIndex];
      const cells = tr.getElementsByTagName('td');
      if (cells && cells.length == 3) {
        const name = cells[0].innerText.trim();
        const [begin, end] = cells[1].innerText.split('-').map((x) => parseFloat(x.trim()));
        const difficulty = cells[2].innerText;
        res.push({ name, range: { begin, end }, difficulty });
      }
    } catch (e) {
      continue;
    }
  }
  return res;
}

export function extractDifficultySections(riverDetail) {
  const data = _.get(riverDetail, 'ExportReky.reka.obtiznosti.obtiznost');
  const array = _.isArray(data) ? data : _.isPlainObject(data) ? [data] : [];
  return array.map((x) => ({
    begin: parseFloat(x.rkm_Od.replace(',', '.')),
    end: parseFloat(x.rkm_Do.replace(',', '.')),
    difficulty: x.stupen,
  }));
}

export function extractDifficultyCoverage(riverDetail, range) {
  const sections = extractDifficultySections(riverDetail);

  return sections.reduce((dct, item) => {
    const intersection = rangeIntersection(item, range);
    if (intersection) {
      return {
        ...dct,
        [item.difficulty]: (dct[item.difficulty] || 0) + Math.abs(intersection.begin - intersection.end),
      };
    }
    return dct;
  }, {});
}

export const isItemShown = (shownObjects) => (rkmItem) => {
  const icons = extractRkmIcons(rkmItem);
  if (icons.length == 0) return shownObjects._other;
  for (const icon of icons) {
    if (shownObjects[icon.slice(0, -2)]) return true;
    if ((icon == 'zacatek_k' || icon == 'konec_zacatek_k' || icon == 'konec_k') && shownObjects['zacatek_konec'])
      return true;
  }
  return false;
};

const extractRiverAdditionalInfo = (path) => (riverDetail) => {
  const res = _.get(riverDetail, path);
  if (_.isPlainObject(res)) return [res];
  if (_.isArray(res)) return res;
  return [];
};

export const extractRiverComments = extractRiverAdditionalInfo('ExportReky.reka.komentare.komentar');
export const extractRiverLogs = extractRiverAdditionalInfo('ExportReky.reka.zapisniky.zapisnik');
export const extractRiverArticles = extractRiverAdditionalInfo('ExportReky.reka.clanky.clanek');

function getAsArray(value) {
  if (_.isPlainObject(value)) return [value];
  if (_.isArray(value)) return value;
  return [];
}

// function extractGaugesCore(gaugesData) {
//   const res = _.get(gaugesData, 'ExportRek.reka.usek');
//   return getAsArray(res);
// }

export function extractGaugeRange(gauge) {
  const begin = parseFloat((gauge.rkmod || '').replace(',', '.'));
  const end = parseFloat((gauge.rkmdo || '').replace(',', '.'));
  return { begin, end };
}

export function parseGauges(gaugesData) {
  return _.compact(
    getAsArray(gaugesData).map((item) => {
      const begin = parseFloat((item.rkmod || '').replace(',', '.'));
      const end = parseFloat((item.rkmdo || '').replace(',', '.'));

      return {
        ...item,
        range: {
          begin: _.isNaN(begin) ? null : begin,
          end: _.isNaN(end) ? null : end,
        },
      };
    })
  );
}

export function extractRiverSemaphoreByRiver(countryGaugesData) {
  const rivers = _.get(countryGaugesData, 'ExportRek.reka');
  if (!rivers) return null;
  return _.mapValues(_.keyBy(getAsArray(rivers), '@ID_reky'), (river, id) => {
    const sections = _.map(getAsArray(_.get(river, 'usek')), 'sjizdnost')
      .filter((x) => x == 1 || x == 2 || x == 3)
      .map((x) => parseInt(x));
    return _.min(sections);
  });
}
export function extractRiverSectionsBySemaphore(countryGaugesData, riverId) {
  const rivers = _.get(countryGaugesData, 'ExportRek.reka');
  if (!rivers) return [];
  const river = getAsArray(rivers).find((x) => x['@ID_reky'] == riverId);
  return getAsArray(_.get(river, 'usek')); // .filter((x) => ['1', '2', '3', 'B', 'P', 'O'].includes(x.sjizdnost));
}

export function extractArticleLink(riverDetail, article) {
  const linkBase = _.get(riverDetail, 'ExportReky.reka.clanky.@odkaz');
  const id = _.get(article, 'ID_clanku');
  if (linkBase && id) return `${linkBase}${id}`;
  return null;
}

export function extractArticleListLink(riverDetail) {
  const odkaz = _.get(riverDetail, 'ExportReky.reka.@odkaz');
  if (odkaz) return `${odkaz}?kilo=clanky`;
  return null;
}

export function extractCommentListLink(riverDetail) {
  const odkaz = _.get(riverDetail, 'ExportReky.reka.@odkaz');
  if (odkaz) return `${odkaz}?kilo=komentar`;
  return null;
}

export function extractLogListLink(riverDetail) {
  const ID_reky = _.get(riverDetail, 'ExportReky.reka.ID_reky');
  if (ID_reky) return `https://www.raft.cz/vodacky-zapisnik.aspx?ID_reky=${ID_reky}`;
  return null;
}

export function extractArticleCount(riverDetail) {
  return _.get(riverDetail, 'ExportReky.reka.clanky.@pocet') || '??';
}

export function extractLogCount(riverDetail) {
  return _.get(riverDetail, 'ExportReky.reka.zapisniky.@pocet') || '??';
}

export function extractCommentCount(riverDetail) {
  return _.get(riverDetail, 'ExportReky.reka.komentare.@pocet') || '??';
}

export function extractIdZeme(riverDetail) {
  return _.get(riverDetail, 'ExportReky.reka.ID_zeme');
}

export function extractRiverNameFromGauge(riverGaugeDetail) {
  return _.get(riverGaugeDetail, 'ExportRek.reka.@nazev') || '??';
}

export function extractSectionsFromGauge(riverGaugeDetail) {
  return getAsArray(_.get(riverGaugeDetail, 'ExportRek.reka.usek'));
}
