import _ from 'lodash';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import useReactRouter from 'use-react-router';

import useT from '../utility/useT';
import useStateDialog from '../utility/useStateDialog';
import ChooseFromListDialog from '../ui-elements/ChooseFromListDialog';

const useStyles = makeStyles((theme) => ({
  flexLine: {
    display: 'flex',
  },
  searchField: {
    marginLeft: '1rem',
    // marginRight: '1rem',
    marginTop: '1rem',
    flexGrow: 1,
  },
}));

export function getSearchValue(history, routerStateField) {
  const search = history.location && history.location.state && history.location.state[`${routerStateField}_search`];
  return search;
}

export default function SearchField({ routerStateField = undefined, onRunFilter, className = undefined }) {
  const classes = useStyles();
  const t = useT();
  const { history } = useReactRouter();

  const [openAddFilter, addFilterDialogProps] = useStateDialog();

  const [searchInState, setSearchInState] = React.useState('');
  const setSearch = (value) =>
    routerStateField
      ? history.replace(history.location.pathname, { ...history.location.state, [`${routerStateField}_search`]: value })
      : setSearchInState(value);
  const search = routerStateField ? getSearchValue(history, routerStateField) : searchInState;

  const setDebouncedFilter = React.useRef(
    // @ts-ignore
    _.debounce((value) => onRunFilter(value), 500)
  );

  const applyFilter = () => {
    onRunFilter(search);
  };
  const clearFilter = () => {
    setSearch('');
    onRunFilter('');
  };

  return (
    <div className={clsx(classes.flexLine, className)}>
      <TextField
        id="outlined-with-placeholder"
        placeholder={t('Search')}
        className={classes.searchField}
        value={search}
        autoComplete="off"
        onChange={(e) => {
          setSearch(e.target.value);
          // onRunFilter(e.target.value);
          // @ts-ignore
          setDebouncedFilter.current(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') applyFilter();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') clearFilter();
        }}
      />
      {/* {
        // @ts-ignore
        <IconButton aria-label="menu" color="primary" onClick={openAddFilter}>
          <AddCircleIcon />
        </IconButton>
      }

      <ChooseFromListDialog items={chooseItems} {...addFilterDialogProps} /> */}
    </div>
  );
}
