import React from 'react';
import FlagAt from './FlagAt';
import FlagCh from './FlagCh';
import FlagCz from './FlagCz';
import FlagDe from './FlagDe';
// import FlagEs from './FlagEs';
import FlagFr from './FlagFr';
import FlagIt from './FlagIt';
import FlagSi from './FlagSi';
import FlagGr from './FlagGr';
import FlagHr from './FlagHr';
import FlagGb from './FlagGb';
import FlagSk from './FlagSk';
import FlagPl from './FlagPl';
import FlagLi from './FlagLi';
import FlagBl from './FlagBl';
import FlagBg from './FlagBg';
import FlagRo from './FlagRo';
import FlagRu from './FlagRu';
import FlagUk from './FlagUk';
import FlagHu from './FlagHu';
import FlagAl from './FlagAl';

export default function Flag({ language = undefined, url = undefined, size = 30 }) {
  if (language === 'cs') return <FlagCz size={size} />;
  if (language === 'en') return <FlagGb size={size} />;
  if (language === 'pl') return <FlagPl size={size} />;
  if (language === 'sk') return <FlagSk size={size} />;
  if (language === 'de') return <FlagDe size={size} />;

  if (url === 'rakousko') return <FlagAt size={size} />;
  if (url === 'cechy' || url === 'morava') return <FlagCz size={size} />;
  if (url === 'swiss') return <FlagCh size={size} />;
  if (url === 'slovinsko') return <FlagSi size={size} />;
  if (url === 'italie') return <FlagIt size={size} />;
  // if (url === 'spanelsko') return <FlagEs size={size} />;
  if (url === 'francie' || url === 'korsica') return <FlagFr size={size} />;
  if (url === 'nemecko') return <FlagDe size={size} />;
  if (url === 'recko') return <FlagGr size={size} />;
  if (url === 'jugo') return <FlagHr size={size} />;
  if (url === 'slovensko') return <FlagSk size={size} />;
  if (url === 'polsko') return <FlagPl size={size} />;
  if (url === 'litva') return <FlagLi size={size} />;
  if (url === 'belorusko') return <FlagBl size={size} />;
  if (url === 'bulharsko') return <FlagBg size={size} />;
  if (url === 'rumunsko') return <FlagRo size={size} />;
  if (url === 'rusko') return <FlagRu size={size} />;
  if (url === 'ukrajina') return <FlagUk size={size} />;
  if (url === 'madarsko') return <FlagHu size={size} />;
  if (url === 'albanie') return <FlagAl size={size} />;

  return null;
}
