import _ from 'lodash';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useReactRouter from 'use-react-router';
import { Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { addCurrentLocationToGuide, guideHasGps } from '../datalayer/guideGps';
import { roundKm } from '../utility/mathutil';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import CloseButtonDialogTitle from '../ui-elements/CloseButtonDialogTitle';
import useT from '../utility/useT';

import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { sendFeedback } from '../datalayer/api';
import { extractRkmKm, extractRiverId, extractRiverGuide } from '../datalayer/extractors';
import useLocation from '../utility/useLocation';
import nativeInterface from '../utility/nativeInterface';
import LoadingInfo from '../ui-elements/LoadingInfo';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
    // backgroundColor: theme.palette.background.paper,
  },
  photo: {
    maxWidth: '40vmin',
    maxHeight: '40vmin',
    margin: theme.spacing(1),
  },
  field: {
    // margin: theme.spacing.unit,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  inputLabel: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  photoWrapper: {
    position: 'relative',
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  displayNone: {
    display: 'none',
  },
}));

function FeedbackDialog({ onClose = undefined, riverDetail, rkmDetail = undefined, open = undefined, ...other }) {
  const classes = useStyles();
  const t = useT();
  const [popis, setPopis] = React.useState('');
  const [autor, setAutor] = React.useState('');
  const [rkm, setRkm] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => setRkm(extractRkmKm(rkmDetail)), [rkmDetail]);
  const ID_reky = extractRiverId(riverDetail);

  const location = useLocation(open);

  const currentRkm = React.useMemo(() => {
    if (open && !rkmDetail && location && location.latitude && location.longitude) {
      let guide = extractRiverGuide(riverDetail);
      if (guideHasGps(guide)) {
        guide = addCurrentLocationToGuide(guide, location);
        const current = guide.find((x) => x.currentLocation);
        if (current) return roundKm(current.rkm).toString();
      }
    }
    return null;
  }, [riverDetail, location, rkmDetail]);

  React.useEffect(() => {
    if (currentRkm != null) setRkm(currentRkm);
  }, [currentRkm]);

  React.useEffect(() => {
    const native = nativeInterface();
    if (open && native && native.requestCamera) native.requestCamera();
  }, [open]);

  function handleClose() {
    onClose();
  }

  const { history } = useReactRouter();

  const openCamera = () =>
    history.push(history.location.pathname, {
      ...history.location.state,
      cameraIsOpen: true,
    });

  const cameraIsOpen = history.location.state && history.location.state.cameraIsOpen;

  const [imageUrls, setImageUrls] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);

  const handleSendFeedback = async () => {
    try {
      setIsUploading(true);
      const res = await sendFeedback({
        autor,
        popis,
        rkm,
        ID_reky,
        imageUrls,
        gps: location && location.latitude && location.longitude ? `${location.latitude},${location.longitude}` : null,
      });
      if (res.state == 'ok') {
        enqueueSnackbar(t('FeedbackSentOk', { count: res.images || 0 }), { variant: 'info' });
        handleClose();
      } else {
        enqueueSnackbar(res.message || t('FeedbackSendingFail'), { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setIsUploading(false);
    }
  };

  const inputFileRef = React.useRef(null);

  return (
    // @ts-ignore
    <>
      <Dialog onClose={handleClose} aria-labelledby="feedback-dialog-title" open={open && !cameraIsOpen} {...other}>
        <CloseButtonDialogTitle id="place-details-dialog-title" onClose={handleClose}>
          {t('SendFeedback')}
        </CloseButtonDialogTitle>
        {isUploading ? (
          <LoadingInfo message={t('SendingFeedback')} />
        ) : (
          <>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>{t('Description')}</InputLabel>
              <TextField className={classes.field} value={popis} onChange={(e) => setPopis(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <InputLabel className={classes.inputLabel}>{t('Author')}</InputLabel>
              <TextField className={classes.field} value={autor} onChange={(e) => setAutor(e.target.value)} />
            </FormGroup>
            {!rkmDetail && (
              <FormGroup>
                <InputLabel className={classes.inputLabel}>{t('RiverKm')}</InputLabel>
                <TextField className={classes.field} value={rkm} onChange={(e) => setRkm(e.target.value)} />
              </FormGroup>
            )}
            <FormGroup>
              <InputLabel className={classes.inputLabel}>
                {imageUrls.length > 0 ? t('Photo') : t('WithoutPhoto')}
              </InputLabel>
              {imageUrls.map((imageUrl) => (
                <div className={classes.photoWrapper}>
                  <img className={classes.photo} src={imageUrl} />
                  <div className={classes.deleteButton}>
                    <IconButton onClick={() => setImageUrls((value) => value.filter((x) => x != imageUrl))}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              ))}
            </FormGroup>
            <Button className={classes.button} variant="contained" color="primary" onClick={openCamera}>
              <Trans>TakePhoto</Trans>
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => {
                const native = nativeInterface();

                if (native) {
                  if (native.showUploadDialog) {
                    window['receiveUploadedData'] = (data) => setImageUrls((value) => [...value, data]);
                    native.showUploadDialog();
                  } else {
                    enqueueSnackbar('Please upgrade app from android store', { variant: 'error' });
                  }
                } else {
                  inputFileRef.current.click();
                }
              }}
            >
              <Trans>UploadPhoto</Trans>
            </Button>
            <input
              type="file"
              accept="image/*"
              className={classes.displayNone}
              ref={inputFileRef}
              onChange={(e1) => {
                const dialog = e1.target;
                if (dialog.files && dialog.files[0]) {
                  const reader = new FileReader();
                  reader.onload = (e2) => {
                    setImageUrls((value) => [...value, e2.target.result]);
                  };
                  reader.readAsDataURL(dialog.files[0]);
                }
              }}
            />
            <Button className={classes.button} variant="contained" color="primary" onClick={handleSendFeedback}>
              <Trans>SendFeedback</Trans>
            </Button>
          </>
        )}
      </Dialog>
      <Dialog
        onClose={() => history.goBack()}
        aria-labelledby="camera-dialog-title"
        open={open && cameraIsOpen}
        fullScreen
      >
        <Camera
          onTakePhoto={(dataUri) => {
            setImageUrls((value) => [...value, dataUri]);
            history.goBack();
          }}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          isImageMirror={false}
          imageType={IMAGE_TYPES.JPG}
          idealResolution={{ width: 1200, height: 900 }}
        />
        <Avatar onClick={() => history.goBack()} className={classes.closeButton}>
          <CloseIcon />
        </Avatar>
      </Dialog>
    </>
  );
}

export default FeedbackDialog;
// export default RkmDetailDialog;
